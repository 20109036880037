// @ts-nocheck
/* eslint jsx-a11y/media-has-caption: 0 */
import { makeStyles, Theme, Grid } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import background from "../../../../assets/images/sections/gameView/backgroundDesktop.webp";
import border from "../../../../assets/images/sections/gameView/border.webp";
import nextDisabled from "../../../../assets/images/nextDisabled.webp";
import nextEnabled from "../../../../assets/images/nextEnabled.webp";
import prevDisabled from "../../../../assets/images/prevDisabled.webp";
import prevEnabled from "../../../../assets/images/prevEnabled.webp";
import char1 from "../../../../assets/images/sections/gameView/gameViewChar1.webp";
import char2 from "../../../../assets/images/sections/gameView/gameViewChar2.webp";
import char3 from "../../../../assets/images/sections/gameView/gameViewChar3.webp";
import trono from "../../../../assets/images/sections/gameView/trono.png";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    fontFamily: "GothamRounded",
    color: "white",
  },
  gridContainer: {
    zIndex: 10,
    maxWidth: "1440px",
    [theme.breakpoints.down("xl")]: {
      margin: "0px 10px 0px 10px",
    },
    width: "100%",
    "&>iframe": {
      width: "100%",
      height: "70%",
    },
  },
  videoVimeo: {
    borderImage: `url(${border}) 30 round`,
    borderImageSlice: "85",
    borderWidth: "35px",
    borderStyle: "solid",
    width: "auto",
    height: "70vh",
    borderRadius: 26,
  },
  assetContainer: {
    backgroundImage: `url(${background})`,
    backgroundPositionY: "bottom",
    backgroundSize: "cover",
    margin: "0 auto",
    height: "100vh",
    paddingTop: 40,
    position: "relative",
    overflow: "hidden",
    width: "100%",
    justifySelf: "center",
    alignSelf: "center",
    justifyContent: "space-around",
    display: "flex",
  },
  borderContainer: {
    width: "100%",
    height: "fit-content",
    position: "relative",
    marginBottom: "30px",
    "&>img": {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
  },
  paginatorContainer: {
    maxWidth: "1440px",
    zIndex: 9,
  },
  contentContainer: {
    maxWidth: "1440px",
    [theme.breakpoints.down("xl")]: {
      margin: "0px 10px 0px 10px",
    },
  },
  rightContainer: {
    [theme.breakpoints.down("lg")]: {
      padding: "0 16vh",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0 120px",
    },
  },
  animationContainer: {
    animation: "fadeoutfadeinV 2s linear",
  },
  controlLeft: {
    float: "right",
    height: "42px",
    width: "42px",
    cursor: "pointer",
  },
  controlRight: {
    float: "left",
    height: "42px",
    width: "42px",
    cursor: "pointer",
  },
  indexSelector: {
    margin: "0 12px",
    width: "30%",
    maxWidth: "223px",
    height: "9px",
    borderRadius: "9px",
  },
  indexContainer: {
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
  indexDisabled: {
    backgroundColor: "white",
  },
  indexEnabled: {
    backgroundColor: "#4C1A45",
  },
  char: {
    position: "absolute",
    width: "25%",
    left: 0,
    bottom: 0,
    zIndex: 5,
  },
  trono: {
    position: "absolute",
    width: "35%",
    right: 0,
    bottom: 0,
    zIndex: 5,
  },
}));

const LandingPageGameView = () => {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState(0);
  const [hoverOn, setHoverOn] = useState("");
  const [classNameSet, setClassName] = useState("");
  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);
  const videoRef3 = useRef(null);

  useEffect(() => {
    setClassName(classes.animationContainer);
  }, [activeIndex]);

  const setTimer = () =>
    setTimeout(() => {
      setClassName(classes.animationContainer);
    }, 100);

  const updateIndex = (newIndex: number) => {
    setClassName("");
    videoRef1.current.pause();
    videoRef2.current.pause();
    videoRef3.current.pause();
    if (newIndex >= 0 && newIndex < sectionsGameView.length) {
      setTimer();
      setTimeout(() => {
        setActiveIndex(newIndex);
      }, 1000);
    } else if (newIndex >= sectionsGameView.length) {
      setTimer();
      setTimeout(() => {
        setActiveIndex(0);
      }, 1000);
    }
  };

  const sectionsGameView = [
    {
      name: "GameView1",
      src: "https://player.vimeo.com/progressive_redirect/playback/692488725/rendition/1080p?loc=external&signature=f8e52ab84da1d3453e47fc2b7eda5c6c31d72896ea83bf980aef20ca2cc7c527",
    },
    {
      name: "GameView2",
      src: "https://player.vimeo.com/progressive_redirect/playback/680898209/rendition/1080p?loc=external&signature=ab8451a6ac39f6f46d6fd3b31b1d69b7ce9886ac67e87bb53e2331a59633229f",
    },
    {
      name: "GameView3",
      src: "https://player.vimeo.com/progressive_redirect/playback/685996692/rendition/1080p?loc=external&signature=e38ba4430f298a0d86fd6e8ab102527d1ba2fc24f5b27c421e630dc8fab1e3cf",
    },
  ];

  return (
    <div className={classes.root} id="GameView">
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        className={classes.assetContainer}
      >
        <Grid
          direction="row"
          container
          alignItems="center"
          justifyContent="center"
          className={`${classes.gridContainer} ${classNameSet}`}
        >
          <video
            width="320"
            height="240"
            controls
            ref={videoRef1}
            className={classes.videoVimeo}
            style={{ display: activeIndex === 0 ? "block" : "none" }}
          >
            <source type="video/mp4" src={sectionsGameView[0].src} />
          </video>

          <video
            width="320"
            height="240"
            controls
            className={classes.videoVimeo}
            ref={videoRef2}
            style={{ display: activeIndex === 1 ? "block" : "none" }}
          >
            <source type="video/mp4" src={sectionsGameView[1].src} />
          </video>

          <video
            width="320"
            height="240"
            controls
            className={classes.videoVimeo}
            ref={videoRef3}
            style={{ display: activeIndex === 2 ? "block" : "none" }}
          >
            <source type="video/mp4" src={sectionsGameView[2].src} />
          </video>
        </Grid>

        <img
          src={char3}
          alt="char1"
          style={{ display: activeIndex === 0 ? "block" : "none" }}
          className={`${classes.char} `}
        />

        <img
          src={char1}
          alt="char1"
          style={{ display: activeIndex === 1 ? "block" : "none" }}
          className={`${classes.char} `}
        />

        <img
          src={char2}
          alt="char1"
          style={{ display: activeIndex === 2 ? "block" : "none" }}
          className={`${classes.char} `}
        />

        <img src={trono} alt="trono" className={classes.trono} />

        <Grid
          direction="row"
          container
          alignItems="center"
          justifyContent="center"
          className={classes.paginatorContainer}
        >
          <Grid item xs={3} sm={1}>
            <img
              src={hoverOn === "left" ? prevEnabled : prevDisabled}
              alt="prev"
              className={classes.controlLeft}
              onClick={() => updateIndex(activeIndex - 1)}
              aria-hidden="true"
              onMouseEnter={() => setHoverOn("left")}
              onMouseLeave={() => setHoverOn("")}
            />
          </Grid>
          <Grid item xs={6} sm={10}>
            <div className={classes.indexContainer}>
              {sectionsGameView.map((section, i) => (
                <div
                  key={`goalKey${i}`}
                  className={`${classes.indexSelector} ${
                    i === activeIndex
                      ? classes.indexEnabled
                      : classes.indexDisabled
                  }`}
                  onClick={() => updateIndex(i)}
                  aria-hidden="true"
                ></div>
              ))}
            </div>
          </Grid>
          <Grid item xs={3} sm={1}>
            <img
              src={hoverOn === "right" ? nextEnabled : nextDisabled}
              alt="next"
              className={classes.controlRight}
              onClick={() => updateIndex(activeIndex + 1)}
              aria-hidden="true"
              onMouseEnter={() => setHoverOn("right")}
              onMouseLeave={() => setHoverOn("")}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default LandingPageGameView;

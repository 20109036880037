import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import background from "../../../../assets/images/header/bg.webp";
import brillos from "../../../../assets/images/header/brillos.webp";
import bleft from "../../../../assets/images/header/bleft.webp";
import bright from "../../../../assets/images/header/bright.webp";
import castle from "../../../../assets/images/header/castle.webp";
import logo from "../../../../assets/images/header/logo.png";
import cookie from "../../../../assets/images/header/cookie.webp";
import lcb from "../../../../assets/images/header/left-cloud-b.webp";
import lct from "../../../../assets/images/header/left-cloud-t.webp";
import rcb from "../../../../assets/images/header/right-cloud-b.webp";
import rct from "../../../../assets/images/header/right-cloud-t.webp";

const useStyles = makeStyles((theme) => ({
  background: {
    width: "100%",
    zIndex: -2,
    height: "100vh",
  },
  container: {
    backgroundColor: "#44e4f9",
    position: "relative",
  },
  containerImages: {
    zIndex: 0,
    maxHeight: "100vh",
  },
  cloud: {
    // display: "none",
    height: "35%",
    position: "absolute",
    top: 0,
    width: "50%",
    zIndex: 4,
    "&.right": {
      right: 0,
      top: "25%",
      animation: "pulseCloud 4s linear infinite forwards",
      width: "100%",
      height: "50%",
    },
    "&.right2": {
      right: 0,
      top: "7%",
      animation: "pulseCloud 4.5s linear infinite forwards",
    },
    "&.left": {
      left: 0,
      top: "5%",
      animation: "pulseCloud 4s linear infinite forwards",
    },
    "&.left2": {
      left: 0,
      top: "30%",
      animation: "pulseCloud 3s linear infinite forwards",
    },
  },
  aboveImages: {
    width: "100%",
    height: "100vh",
    zIndex: 1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  logo: {
    width: "30%",
    height: "auto",
    position: "absolute",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    top: "15%",
    zIndex: 5,
  },
  castle: {
    width: "45%",
    height: "auto",
    position: "absolute",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    top: "8%",
    zIndex: 5,
  },
  candyL: {
    width: "50%",
    height: "auto",
    bottom: "0%",
    right: "0%",
    position: "absolute",
    zIndex: 10,
  },
  candyR: {
    width: "50%",
    height: "auto",
    bottom: "0%",
    left: "0%",
    position: "absolute",
    zIndex: 10,
  },
  cookie: {
    width: "20%",
    height: "auto",
    position: "absolute",
    left: "10%",
    top: "15%",
    zIndex: 11,
  },
}));

const LandingPageHeader = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container id="header" className={classes.container}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={`${classes.containerImages} clouds`}
        >
          <img
            className={`${classes.cloud} left2 `}
            src={lcb}
            alt="Logo Sugar Kingdom"
          />
          <img
            className={`${classes.cloud} left `}
            src={lct}
            alt="Logo Sugar Kingdom"
          />
          <img
            className={`${classes.cloud} right `}
            src={rcb}
            alt="Logo Sugar Kingdom"
          />
          <img
            className={`${classes.cloud} right2 `}
            src={rct}
            alt="Logo Sugar Kingdom"
          />
          <img
            className={`${classes.cookie} animate__animated customSlideInLeft animate__delay-1s`}
            src={cookie}
            alt="Logo Sugar Kingdom"
          />

          <img
            className={`${classes.castle} animate__animated animate__fadeIn animate__delay-1s`}
            src={castle}
            alt="Logo Sugar Kingdom"
          />
          <img
            className={`${classes.logo}`}
            src={logo}
            alt="Logo Sugar Kingdom"
          />
          <img
            className={`${classes.candyR} animate__animated animate__slideInLeft animate__slower`}
            src={bright}
            alt="Logo Sugar Kingdom"
          />
          <img
            className={`${classes.candyL} animate__animated animate__slideInRight animate__slower`}
            src={bleft}
            alt="Logo Sugar Kingdom"
          />

          <img
            className={`${classes.aboveImages} animate__animated animate__heartBeat animate__slow animate__infinite`}
            src={brillos}
            alt="Logo Sugar Kingdom"
          />
          <img
            className={classes.background}
            src={background}
            alt="Logo Sugar Kingdom"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default LandingPageHeader;

import { Grid, Hidden, makeStyles, Theme } from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-scroll";
import logo from "../../../../assets/images/logo_new.webp";
import telegramDisabled from "../../../../assets/images/socialMediaButtons/telegramDisabled.webp";
import telegramEnabled from "../../../../assets/images/socialMediaButtons/telegramEnabled.webp";
import discordDisabled from "../../../../assets/images/socialMediaButtons/discordDisabled.webp";
import discordEnabled from "../../../../assets/images/socialMediaButtons/discordEnabled.webp";
import twDisabled from "../../../../assets/images/socialMediaButtons/twDisabled.webp";
import twEnabled from "../../../../assets/images/socialMediaButtons/twEnabled.webp";
import background from "../../../../assets/images/sections/contactUs/background.webp";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    textAlign: "center",
    background: "#C412AB",
    fontFamily: "GothamRounded",
    color: "white",
    height: "100vh",
  },
  container: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    height: "100%",
    padding: "90px 30px 30px 30px",
    [theme.breakpoints.up("lg")]: {
      padding: "250px 30px 30px 30px",
    },
  },
  menu: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "15px",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  menuRRSS: {
    display: "flex",
    maxWidth: "200px",
    justifyContent: "center",
    marginBottom: "30px",
    margin: "0 auto",
  },
  sectionContent: {
    justifyContent: "space-between",
    alignItems: "end",
  },
  icon: {
    height: "40px!important",
  },
  marginIcon: {
    marginBottom: "10px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "16px",
    },
  },
  menuItem: {
    color: "white",
    textDecoration: "none",
    letterSpacing: "1.3px",
    fontSize: "14px",
    margin: "0 15px 25px 15px",
    display: "block",
    "&:last-child": {
      marginBottom: "0px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "0px",
      letterSpacing: "1.1px",
    },
  },
  secondColumn: {
    display: "flex",
    justifyContent: "space-between",
  },
  logo: {
    maxWidth: "250px",
    marginBottom: "50px",
  },
  item: {},
  imageLeft: {
    width: "110%",
    maxWidth: "450px",
  },
  image: {
    width: "110%",
    maxWidth: "450px",
  },
  iconLink: {
    width: "40px",
    height: "40px",
    fontSize: "15px",
    color: "transparent",
    borderRadius: "50%",
    margin: "0 auto",
    padding: "0 25px 0 25px",
  },
  iconLinkWithoutBg: {
    width: "37px",
    height: "37px",
    fontSize: "15px",
    color: "transparent",
    backgroundColor: "white",
    borderRadius: "50%",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  copyright: {
    color: "white",
    letterSpacing: "1.2px",
    marginBottom: "0px",
    fontSize: "0.8rem",
  },
  animationRight: {
    animation: "lightSpeedInRight 2s ease-out",
  },
  animationLeft: {
    animation: "lightSpeedInLeft 2s ease-out",
  },
  joinCommunity: {
    fontSize: "28px",
    fontWeight: 700,
  },
  imageMedia: {
    width: "54px",
    height: "54px",
  },
}));
interface props {
  handlePageChange: any;
  currentPage: number;
}
const LandingPageContactUs = ({ handlePageChange, currentPage }: props) => {
  const classes = useStyles();
  const [isHoverImage, setIsHoverImage] = useState(-1);
  return (
    <>
      <div className={classes.root}>
        <div className={classes.container}>
          <Grid
            className={classes.sectionContent}
            container
            direction="row"
            spacing={2}
          >
            <Hidden smDown>
              <Grid item className={classes.item}></Grid>
            </Hidden>
            <Grid item xs={12} sm={12} md={8} lg={8} className={classes.item}>
              <Link
                activeClass="active"
                to="header"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onClick={() => handlePageChange(0)}
                onFocus={() => void 0}
                aria-hidden="true"
              >
                <span>
                  <img
                    src={logo}
                    alt="contactUsLogoSugarKingdom"
                    className={classes.logo}
                  />
                </span>
              </Link>
              <Grid
                container
                direction="column"
                className={classes.secondColumn}
              >
                <Grid item className={`${classes.menu} w-p-100`}>
                  <Link
                    style={{ marginBottom: "5px" }}
                    activeClass="active"
                    to="welcome"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                    onClick={() => handlePageChange(1)}
                    onFocus={() => void 0}
                    aria-hidden="true"
                  >
                    <span className={classes.menuItem}>Welcome!</span>
                  </Link>
                  <Link
                    style={{ marginBottom: "5px" }}
                    activeClass="active"
                    to="gameView"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                    onClick={() => handlePageChange(2)}
                    onFocus={() => void 0}
                    aria-hidden="true"
                  >
                    <span className={classes.menuItem}>Game View</span>
                  </Link>
                  <Link
                    style={{ marginBottom: "5px" }}
                    activeClass="active"
                    to="ourGoal"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                    onClick={() => handlePageChange(3)}
                    onFocus={() => void 0}
                    aria-hidden="true"
                  >
                    <span className={classes.menuItem}>Our Goal</span>
                  </Link>
                  <Link
                    style={{ marginBottom: "5px" }}
                    activeClass="active"
                    to="team"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                    onClick={() => handlePageChange(5)}
                    onFocus={() => void 0}
                    aria-hidden="true"
                  >
                    <span className={classes.menuItem}>Team & ADV</span>
                  </Link>

                  <Link
                    style={{ marginBottom: "5px" }}
                    activeClass="active"
                    to="docs"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                    onClick={() => handlePageChange(8)}
                    onFocus={() => void 0}
                    aria-hidden="true"
                  >
                    <span className={classes.menuItem}>Documents</span>
                  </Link>
                </Grid>
                <Grid item className={"w-p-100"}>
                  <p className={classes.joinCommunity}>Join our community</p>
                </Grid>
                <Grid item className={`${classes.menuRRSS} w-p-100`}>
                  {/* If you want to use RRSS, You have to uncomment this */}
                  <a
                    href="https://t.me/+X3rSQ4M1yYVkNTYx"
                    className={classes.iconLink}
                  >
                    <img
                      onMouseOver={(e) => setIsHoverImage(1)}
                      onMouseLeave={(e) => setIsHoverImage(-1)}
                      aria-hidden="true"
                      onFocus={() => void 0}
                      className={classes.imageMedia}
                      src={
                        isHoverImage === 1 ? telegramEnabled : telegramDisabled
                      }
                      alt="contact us icon T"
                    />
                  </a>
                  <a
                    href="https://discord.gg/wevSC3Ty42"
                    className={classes.iconLink}
                  >
                    <img
                      onMouseOver={(e) => setIsHoverImage(2)}
                      onMouseLeave={(e) => setIsHoverImage(-1)}
                      aria-hidden="true"
                      onFocus={() => void 0}
                      className={classes.imageMedia}
                      src={
                        isHoverImage === 2 ? discordEnabled : discordDisabled
                      }
                      alt="contact us icon D"
                    />
                  </a>
                  <a
                    href="https://twitter.com/SugarKingdomNFT"
                    className={classes.iconLink}
                  >
                    <img
                      onMouseOver={(e) => setIsHoverImage(3)}
                      onMouseLeave={(e) => setIsHoverImage(-1)}
                      aria-hidden="true"
                      onFocus={() => void 0}
                      src={isHoverImage === 3 ? twEnabled : twDisabled}
                      alt="contact us icon Tw"
                      className={classes.imageMedia}
                    />
                  </a>
                </Grid>
                <Grid item className={"w-p-100"}>
                  <p className={classes.copyright}>
                    ©2022 Sugar Kingdom! All Rights Reserved.
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Hidden smDown>
              <Grid item className={classes.item}></Grid>
            </Hidden>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default LandingPageContactUs;

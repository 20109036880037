import { Grid, makeStyles, Theme } from "@material-ui/core";
import React, { useRef, useState } from "react";
import nextDisabled from "../../../../assets/images/nextDisabled.webp";
import nextEnabled from "../../../../assets/images/nextEnabled.webp";
import prevDisabled from "../../../../assets/images/prevDisabled.webp";
import prevEnabled from "../../../../assets/images/prevEnabled.webp";
import background_1 from "../../../../assets/images/sections/welcome/fondo.png";
import casas from "../../../../assets/images/sections/welcome/casas.png";
import chocolate from "../../../../assets/images/sections/welcome/chocolate.png";
import castillo from "../../../../assets/images/sections/welcome/castillo.png";
import nubeB from "../../../../assets/images/sections/welcome/nube B.png";
import nubeC from "../../../../assets/images/sections/welcome/nube C.png";
import nubeG from "../../../../assets/images/sections/welcome/nube G.png";
import nubeE from "../../../../assets/images/sections/welcome/nube E.png";
import nubeF from "../../../../assets/images/sections/welcome/nube F.png";

import { useIntersectionObserver } from "../../../../hooks/IntersectionObserver.hook";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    fontFamily: "GothamRounded",
    color: "#4C1A45",
  },
  background: {
    margin: "0 auto",
    backgroundImage: `url(${background_1})`,
    backgroundPositionX: "right",
    backgroundSize: "cover",
    padding: "40px",
    height: "100vh",
    zIndex: -2,
  },
  animationRight: {
    animation: "lightSpeedInRight 2s ease-out",
  },
  controlLeft: {
    float: "right",
    height: "42px",
    width: "42px",
    cursor: "pointer",
  },
  controlRight: {
    float: "left",
    height: "42px",
    width: "42px",
    cursor: "pointer",
  },
  indexSelector: {
    margin: "0 12px",
    width: "30%",
    maxWidth: "223px",
    height: "9px",
    borderRadius: "9px",
  },
  indexContainer: {
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
  indexDisabled: {
    backgroundColor: "white",
  },
  indexEnabled: {
    backgroundColor: "#4C1A45",
  },
  contentText: {
    fontWeight: 900,
    [theme.breakpoints.down("md")]: {
      fontSize: "2vh",
      paddingBottom: "5px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
      paddingBottom: "10px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "20px",
      paddingBottom: "15px",
    },
  },
  contentTextLast: {
    fontSize: "30px",
    fontWeight: 900,
    paddingTop: "20px",
    [theme.breakpoints.down("md")]: {
      fontSize: "3vh",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "30px",
    },
  },
  title: {
    fontWeight: 900,
    display: "block",
    [theme.breakpoints.down("md")]: {
      fontSize: "8vh",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "60px",
    },
  },
  titleContainer: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: "30px",
      paddingTop: "80px",
      fontSize: "8vh",
    },

    [theme.breakpoints.up("md")]: {
      paddingLeft: "30px",
      paddingTop: "110px",
    },
  },
  textContainer: {
    paddingTop: "32px",
    [theme.breakpoints.down("md")]: {
      paddingTop: "14px",
    },
  },
  titleAnimation: {
    opacity: 1,
    animation: "fadeInDown 2s both 1",
  },
  textAnimation: {
    animation: "fadeInLeft 2s both 1",
  },
  hiddenText: {
    opacity: 0,
  },
  bodyContainer: {
    maxWidth: "1440px",
    height: "100%",
    width: "100%",
    "& .MuiGrid-root": {
      zIndex: 10,
    },
  },
  houses: {
    height: "90%",
    width: "100%",
    zIndex: 1,
    position: "absolute",
    top: "10vh",
    right: 0,
  },
  chocolate: {
    bottom: "5%",
    right: "5%",
    width: "25%",
    position: "absolute",
    zIndex: 1,
  },
  castillo: {
    minWidth: 400,
    top: "5%",
    left: "5%",
    width: "25%",
    position: "absolute",
    zIndex: 1,
  },
  nubeC: {
    top: "10%",
    right: "30%",
    width: "40%",
    position: "absolute",
    zIndex: 1,
  },
  nubeE: {
    top: "6%",
    left: "100%",
    width: "40%",
    position: "absolute",
    zIndex: 1,
    animation: "moveclouds 35s linear infinite",
  },
  nubeF: {
    top: "25%",
    left: "100%",
    width: "25%",
    position: "absolute",
    zIndex: 1,
    animation: "moveclouds 30s ease-in-out infinite",
  },
  nubeB: {
    top: "0%",
    right: "0%",
    width: "30%",
    position: "absolute",
    zIndex: 1,
  },
  nubeG: {
    top: "6%",
    left: "0%",
    width: "15%",
    position: "absolute",
    zIndex: 1,
  },
}));

const LandingPageDidYouKnow = () => {
  const classes = useStyles();

  const refSection = useRef(null);

  const sectionsWelcome = [
    {
      name: "welcome1",
      title: "A Sugar Kingdom!",
      text: [
        `A new place of fantasy, where dreams come true. There are great prizes and lots of fun.`,
        `A king who protects the land of sweetness with his friends.`,
        `It is a constant challenge, candies don't stop falling!`,
        `Time is running out, are you ready?`,
      ],
    },
    {
      name: "welcome2",
      title: "Free to play game and catching experience",
      text: [
        `Play match-3, buy NFTs, compete with other users and be part of the sugar land!`,
        `It's free-to-play, you can try it without risking capital.`,
        `The sooner you start playing, the more tokens you earn.`,
        `With Sugar Kingdom, your time is well rewarded!`,
      ],
    },
    {
      name: "welcome3",
      title: "Give me Sugar!",
      text: [
        `Play to earn in Sugar Kingdom!`,
        `Explore the NFTs, buy card boosters, get limited characters and use them within the platform!`,
        `Do you like sweet things?`,
      ],
    },
  ];
  const [activeIndex, setActiveIndex] = useState(0);
  const [hoverOn, setHoverOn] = useState("");
  const [isRunning, setIsRunning] = useState(false);

  const setTimer = () =>
    setTimeout(() => {
      setIsRunning(false);
    }, 500);

  const updateIndex = (newIndex: number) => {
    setIsRunning(true);
    if (newIndex >= 0 && newIndex < sectionsWelcome.length) {
      setActiveIndex(newIndex);
      setTimer();
    } else if (newIndex >= sectionsWelcome.length) {
      setActiveIndex(0);
      setTimer();
    }
  };

  const isSectionVisible = useIntersectionObserver(
    refSection,
    { threshold: 0 },
    true
  );

  return (
    <>
      <div className={classes.root} id="welcome">
        <img src={nubeB} className={classes.nubeB} alt="" />
        <img src={nubeC} className={classes.nubeC} alt="" />
        <img src={nubeG} className={classes.nubeG} alt="" />
        <img src={nubeE} className={classes.nubeE} alt="" />
        <img src={nubeF} className={classes.nubeF} alt="" />
        <img src={castillo} className={classes.castillo} alt="" />
        <img src={casas} className={classes.houses} alt="" />
        <img src={chocolate} className={classes.chocolate} alt="" />
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          className={classes.background}
        >
          <Grid
            direction="column"
            container
            alignItems="center"
            justifyContent="space-between"
            className={classes.bodyContainer}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              className={`${classes.titleContainer} `}
            >
              <Grid item xs={12}>
                <span
                  ref={refSection}
                  className={`
                ${classes.title} 
                ${
                  isSectionVisible && !isRunning
                    ? classes.titleAnimation
                    : classes.hiddenText
                } 
                `}
                >
                  {sectionsWelcome[activeIndex].title}
                </span>
              </Grid>

              <Grid
                item
                xs={6}
                className={`
              ${classes.textContainer} 
              ${
                isSectionVisible && !isRunning
                  ? classes.textAnimation
                  : classes.hiddenText
              } 
              `}
              >
                {sectionsWelcome[activeIndex].text.map((text, i) => (
                  <div
                    className={
                      i === sectionsWelcome[activeIndex].text.length - 1
                        ? classes.contentTextLast
                        : classes.contentText
                    }
                    key={`text${i}`}
                  >
                    {text}
                  </div>
                ))}
              </Grid>
              <Grid item xs={6}></Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={3} sm={1}>
                <img
                  src={hoverOn === "left" ? prevEnabled : prevDisabled}
                  alt="prev"
                  className={classes.controlLeft}
                  onClick={() => updateIndex(activeIndex - 1)}
                  aria-hidden="true"
                  onMouseEnter={() => setHoverOn("left")}
                  onMouseLeave={() => setHoverOn("")}
                />
              </Grid>
              <Grid item xs={6} sm={10}>
                <div className={classes.indexContainer}>
                  {sectionsWelcome.map((section, i) => (
                    <div
                      key={`goalKey${i}`}
                      className={`${classes.indexSelector} ${
                        i === activeIndex
                          ? classes.indexEnabled
                          : classes.indexDisabled
                      }`}
                      onClick={() => updateIndex(i)}
                      aria-hidden="true"
                    ></div>
                  ))}
                </div>
              </Grid>
              <Grid item xs={3} sm={1}>
                <img
                  src={hoverOn === "right" ? nextEnabled : nextDisabled}
                  alt="next"
                  className={classes.controlRight}
                  onClick={() => updateIndex(activeIndex + 1)}
                  aria-hidden="true"
                  onMouseEnter={() => setHoverOn("right")}
                  onMouseLeave={() => setHoverOn("")}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LandingPageDidYouKnow;

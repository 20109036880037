import { Grid, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import background from "../../../../assets/images/sections/docs/backgroundMobile.webp";
import docCard from "../../../../assets/images/sections/docs/docCard.webp";
import doc1 from "../../../../assets/images/sections/docs/imageDoc1.webp";
import doc2 from "../../../../assets/images/sections/docs/imageDoc2.webp";
// import doc3 from "../../../../assets/images/sections/docs/imageDoc3.webp";
import CarouselComponent from "./Carousel";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    fontFamily: "GothamRounded",
    color: "white",
  },
  title: {
    textAlign: "center",
    display: "block",
    fontWeight: 900,
    paddingTop: "40px",
    fontSize: "24px",
  },
  sectionContent: {
    justifyContent: "center",
    marginBottom: 50,
    padding: "80px 0px",
  },
  cardContainer: {
    height: "43vh",
    width: "34vh",
    alignContent: "start",
    backgroundSize: "contain",
    backgroundImage: `url(${docCard})`,
    marginRight: "auto",
    marginLeft: "auto",
    color: "#683A16",
    padding: "0 20px",
  },
  viewMore: {
    fontSize: "18px",
    fontFamily: "GothamRounded",
    color: "black",
    fontWeight: 700,
    textAlign: "end",
  },
  linkMore: {
    color: "#4C1A45",
  },
  background: {
    margin: "0 auto",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    padding: "40px",
    height: "100vh",
    zIndex: -2,
  },
  cardSection1: {
    fontSize: "12px",
    fontWeight: 900,
  },
  cardSection2: {
    paddingTop: "2vh",
    fontWeight: 900,
    color: "#683A16",
    textAlign: "center",
    fontSize: "3vh",
  },
  cardSection3: {
    paddingTop: "10px",
    fontWeight: 350,
    color: "#A86628",
    textAlign: "center",
    fontSize: "2vh",
  },
  cardSection4: {
    fontFamily: "GothamRounded",
    color: "#4C1A45",
    fontWeight: 900,
    textAlign: "end",
    width: "100%",
    fontSize: "2vh",
    paddingRight: "15px",
  },
  imageDoc: {
    maxWidth: "12vh",
    padding: "0px",
    display: "block",
    margin: "auto",
  },
  bodyContainer: {
    maxWidth: "1440px",
  },
  textAnimation: {
    animation: "tada 2s both 1",
  },
}));

const LandingPageDocsMobile = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="space-around"
          className={classes.background}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            className={classes.bodyContainer}
          >
            <Grid item xs={12} className={`${classes.title}`}>
              Documents
            </Grid>
          </Grid>

          <CarouselComponent>
            {/* <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="space-around"
              className={classes.cardContainer}
            >
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12} className={classes.cardSection1}>
                  <img
                    src={doc3}
                    alt="imageheader"
                    className={classes.imageDoc}
                  />
                </Grid>
                <Grid item xs={12} className={classes.cardSection2}>
                  Pitch Deck
                </Grid>
                <Grid item xs={12} className={classes.cardSection3}>
                  Take a look at our business plan!
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                className={classes.cardSection4}
              >
                <a
                  className={classes.linkMore}
                  href={"pitchDeck.pdf"}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  View More
                </a>
              </Grid>
            </Grid> */}

            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="space-around"
              className={classes.cardContainer}
            >
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12} className={classes.cardSection1}>
                  <img
                    src={doc1}
                    alt="imageheader"
                    className={classes.imageDoc}
                  />
                </Grid>
                <Grid item xs={12} className={classes.cardSection2}>
                  White Paper
                </Grid>
                <Grid item xs={12} className={classes.cardSection3}>
                  Know more about Sugar Kingdom!
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                className={classes.cardSection4}
              >
                <a
                  className={classes.linkMore}
                  href="https://sugarkingdom.gitbook.io/sugar-kingdom-whitepaper-1.0/"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  View More
                </a>
              </Grid>
            </Grid>

            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="space-around"
              className={classes.cardContainer}
            >
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12} className={classes.cardSection1}>
                  <img
                    src={doc2}
                    alt="imageheader"
                    className={classes.imageDoc}
                  />
                </Grid>
                <Grid item xs={12} className={classes.cardSection2}>
                  Tokenomics
                </Grid>
                <Grid item xs={12} className={classes.cardSection3}>
                  A professional economy design.
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                className={classes.cardSection4}
              >
                <a
                  className={classes.linkMore}
                  href="https://sugarkingdom.gitbook.io/sugar-kingdom-whitepaper-1.0/tokenomics/candy"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  View More
                </a>
              </Grid>
            </Grid>
          </CarouselComponent>
        </Grid>
      </div>
    </>
  );
};

export default LandingPageDocsMobile;

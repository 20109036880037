import { Grid, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import team25 from "../../../../assets/images/sections/team/aCarracedo.webp";
import team13 from "../../../../assets/images/sections/team/aferrercrafter.webp";
import team26 from "../../../../assets/images/sections/team/aJaimez.webp";
import team19 from "../../../../assets/images/sections/team/aLorenz.webp";
import team28 from "../../../../assets/images/sections/team/aSagula.webp";
import team18 from "../../../../assets/images/sections/team/aTremosa.webp";
import background from "../../../../assets/images/sections/team/backgroundTeam.webp";
import team16 from "../../../../assets/images/sections/team/christiankuroki.webp";
import team23 from "../../../../assets/images/sections/team/dGonzalez.webp";
import team1 from "../../../../assets/images/sections/team/fLinzuain.webp";
import frontCardImage from "../../../../assets/images/sections/team/frontCard.webp";
import team7 from "../../../../assets/images/sections/team/fsabureau.webp";
import team29 from "../../../../assets/images/sections/team/gblazevich.webp";
import team14 from "../../../../assets/images/sections/team/henrysuarezv.webp";
import team27 from "../../../../assets/images/sections/team/hFoncea.webp";
import team6 from "../../../../assets/images/sections/team/itsshahzaib.webp";
import team11 from "../../../../assets/images/sections/team/jDemarco.webp";
import team12 from "../../../../assets/images/sections/team/jMazzara.webp";
import team24 from "../../../../assets/images/sections/team/jpfernandez.webp";
import team15 from "../../../../assets/images/sections/team/juanadriantapia.webp";
import team21 from "../../../../assets/images/sections/team/kirubakaranreddy.webp";
import logoMono from "../../../../assets/images/sections/team/logomono.png";
import team8 from "../../../../assets/images/sections/team/mCotrofe.webp";
import team2 from "../../../../assets/images/sections/team/mCrespo.webp";
import team3 from "../../../../assets/images/sections/team/mPietrantonio.webp";
import team17 from "../../../../assets/images/sections/team/pekkakelkka.webp";
import team5 from "../../../../assets/images/sections/team/pHernandez.webp";
import team4 from "../../../../assets/images/sections/team/PMoauro.webp";
import team20 from "../../../../assets/images/sections/team/santiagogutierrezquinzan.webp";
import team10 from "../../../../assets/images/sections/team/sLosanovscky.webp";
import team9 from "../../../../assets/images/sections/team/tTrippler.webp";
import team22 from "../../../../assets/images/sections/team/Znamenskiy.webp";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    fontFamily: "GothamRounded",
    color: "#4C1A45",
  },
  background: {
    margin: "0 auto",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    padding: "40px",
    height: "100vh",
    zIndex: -2,
  },
  imageTeam: {
    maxWidth: "12vh",
    padding: "0px",
    display: "block",
    margin: "auto",
  },
  contentText: {
    textAlign: "center",
    paddingTop: "15px",
    fontWeight: 900,
    fontSize: "10px",
  },
  title: {
    textAlign: "center",
    display: "block",
    fontWeight: 900,
    fontSize: "24px",
  },
  cardWrapper: {
    display: "block",
    width: "100%",
  },

  bodyContainer: {
    paddingTop: "70px",
  },
  cardBodyContainer: {
    height: "100%",
    padding: "0px 7px 0px 7px",
  },
  cardSection1: {
    fontSize: "12px",
    fontWeight: 900,
  },
  cardSection2: {
    fontWeight: 900,
    color: "#683A16",
    textAlign: "center",
    fontSize: "20px",
  },
  cardSection3: {
    fontWeight: 350,
    color: "#A86628",
    textAlign: "center",
    fontSize: "10px",
  },
  cardSection4: {
    top: "60%",
    width: "15%",
    position: "absolute",
    margin: "auto",
    left: 0,
    right: 0,
  },
  cardContainer: {
    height: "43vh",
    width: "34vh",
    backgroundSize: "100% 100%",
    backgroundImage: `url(${frontCardImage})`,
    marginRight: "auto",
    marginLeft: "auto",
    justifyContent: "space-between",
    color: "#683A16",
    padding: "40px 20px",
  },
  linkedinImage: {
    width: "35px",
  },
}));

const fci = new Image();
fci.src = frontCardImage;

const team1Image = new Image();
team1Image.src = team1;

const team2Image = new Image();
team2Image.src = team2;

const team3Image = new Image();
team3Image.src = team3;

const team4Image = new Image();
team4Image.src = team4;

const team5Image = new Image();
team5Image.src = team5;

const team6Image = new Image();
team6Image.src = team6;

const team7Image = new Image();
team7Image.src = team7;

const team8Image = new Image();
team8Image.src = team8;

const team9Image = new Image();
team9Image.src = team9;

const team10Image = new Image();
team10Image.src = team10;

const team11Image = new Image();
team11Image.src = team11;

const team12Image = new Image();
team12Image.src = team12;
const team13Image = new Image();
team13Image.src = team13;

const team14Image = new Image();
team14Image.src = team14;

const team15Image = new Image();
team15Image.src = team15;

const team16Image = new Image();
team16Image.src = team16;

const team17Image = new Image();
team17Image.src = team17;

const team18Image = new Image();
team18Image.src = team18;

const team19Image = new Image();
team19Image.src = team19;

const team20Image = new Image();
team20Image.src = team20;

const team21Image = new Image();
team21Image.src = team21;

const team22Image = new Image();
team22Image.src = team22;

const team23Image = new Image();
team23Image.src = team23;

const team24Image = new Image();
team24Image.src = team24;

const team25Image = new Image();
team25Image.src = team25;

const team26Image = new Image();
team26Image.src = team26;

const team27Image = new Image();
team27Image.src = team27;

const team28Image = new Image();
team28Image.src = team28;

const team29Image = new Image();
team29Image.src = team29;

const sectionsTeam = [
  {
    name: "team1",
    title: "Francisco Linzuain",
    text: `CEO`,
    link: "https://www.linkedin.com/in/francis-linzuain/",
    image: team1Image.src,
  },
  {
    name: "team2",
    title: "Marcelo Crespo",
    text: `Art Director`,
    link: "https://www.linkedin.com/in/marcelo-crespo-9162575b/",
    image: team2Image.src,
  },
  {
    name: "team3",
    title: "Mariano Di Pietrantonio",
    text: `Marketing Lead Advisor.`,
    link: "https://www.linkedin.com/in/mariano-di-pietrantonio-0ab4a023/",
    image: team3Image.src,
  },

  {
    name: "team1",
    title: "Pablo Moauro",
    text: `CFO`,
    link: "https://www.linkedin.com/in/pablo-moauro-71a9276a/",
    image: team4Image.src,
  },
  {
    name: "team2",
    title: "Prince Hernandez",
    text: `Sr. Front End Developer`,
    link: "https://www.linkedin.com/in/princehernandez/",
    image: team5Image.src,
  },
  {
    name: "team3",
    title: "Shahzaib Ali",
    text: `Business Lead Adv.`,
    link: "https://www.linkedin.com/in/itsshahzaib/",
    image: team6Image.src,
  },

  {
    name: "team1",
    title: "Federico Sabureau",
    text: `CTO`,
    link: "https://www.linkedin.com/in/juan-federico-sabureau-515902168/",
    image: team7Image.src,
  },
  {
    name: "team2",
    title: "Marcelo Cotrofe",
    text: `Sr. Software Engineer`,
    link: "https://www.linkedin.com/in/marcelo-cotrofe-22b15241/",
    image: team8Image.src,
  },
  {
    name: "team3",
    title: "Timo Trippler",
    text: `Fundraising & Commercial Strategy Adv.`,
    link: "https://www.linkedin.com/in/timotrippler/",
    image: team9Image.src,
  },

  {
    name: "team1",
    title: "Santiago Losanovscky",
    text: `CPO`,
    link: "https://www.linkedin.com/in/santiago-p%C3%A9rez-losanovscky-a91877172/",
    image: team10Image.src,
  },
  {
    name: "team2",
    title: "Juan Ignacio De Marco",
    text: `Sound Designer & Product Development`,
    link: "https://www.linkedin.com/in/juanigdemarco/",
    image: team11Image.src,
  },
  {
    name: "team3",
    title: "José Ignacio Mazzara",
    text: `Technical Lead Advisor`,
    link: "https://www.linkedin.com/in/imazzara/",
    image: team12Image.src,
  },
  {
    name: "team1",
    title: "Arnaldo Corzo Ferrer",
    text: `Unity Developer & Back End Engineer`,
    link: "https://www.linkedin.com/in/aferrercrafter/",
    image: team13Image.src,
  },
  {
    name: "team2",
    title: "Henry Suarez",
    text: `Sr. Front End Developer`,
    link: "https://www.linkedin.com/in/henrysuarezv/",
    image: team14Image.src,
  },
  {
    name: "team3",
    title: "Juan Tapia",
    text: `Software & Technical Infrastructure Adv.`,
    link: "https://www.linkedin.com/in/juanadriantapia/",
    image: team15Image.src,
  },
  {
    name: "team1",
    title: "Christian Kuroki",
    text: `Blockchain DevOps Specialist Adv.`,
    link: "https://www.linkedin.com/in/christiankuroki/",
    image: team16Image.src,
  },
  {
    name: "team2",
    title: "Pekka Kelkka",
    text: `Business Development & Project Growth Adv.`,
    link: "https://www.linkedin.com/in/pekkakelkka/",
    image: team17Image.src,
  },
  {
    name: "team3",
    title: "Angel Tremosa",
    text: `Sr Technical Artist`,
    link: "https://www.linkedin.com/in/angel-tremosa-86807a33/",
    image: team18Image.src,
  },
  {
    name: "team1",
    title: "Aixa Ostuvald",
    text: `Artist 2d and Ui Designer`,
    link: "https://www.linkedin.com/in/aixa-ostuvald-lorenz-602554205/",
    image: team19Image.src,
  },
  {
    name: "team2",
    title: "Santiago Quinzan",
    text: `Ux Ui Designer`,
    link: "https://www.linkedin.com/in/santiagogutierrezquinzan/",
    image: team20Image.src,
  },
  {
    name: "team3",
    title: "Kirubakaran Reddy",
    text: `Marketing & Fundraising Strategist`,
    link: "https://www.linkedin.com/in/kirubakaranreddy/",
    image: team21Image.src,
  },
  {
    name: "team1",
    title: "Dr. Denis Znamenskiy",
    text: `Business Strategy & Management Adv.`,
    link: "https://www.linkedin.com/in/denisznamenskiy/",
    image: team22Image.src,
  },
  {
    name: "team2",
    title: "Damian Gonzalez",
    text: `Cloud Engineer Manager`,
    link: "https://www.linkedin.com/in/damian-gonzalez-05702676/",
    image: team23Image.src,
  },
  {
    name: "team3",
    title: "JP Fernandez",
    text: `Business Strategy & Media Adv.`,
    link: "https://www.linkedin.com/in/jp-fernandez/",
    image: team24Image.src,
  },
  {
    name: "team1",
    title: "Adrian Pariente",
    text: `Tech Lead`,
    link: "https://www.linkedin.com/in/adrian-pariente-carracedo/",
    image: team25Image.src,
  },
  {
    name: "team2",
    title: "Ariel Jaimez",
    text: `Software Architect & Blockchain Developer`,
    link: "https://www.linkedin.com/in/ariel-jaimez-218b897/",
    image: team26Image.src,
  },
  {
    name: "team3",
    title: "Hugo Foncea",
    text: `Back End Lead`,
    link: "https://www.linkedin.com/in/hugo-foncea-a9403a48/",
    image: team27Image.src,
  },
  {
    name: "team1",
    title: "Alejandro Sagula",
    text: `Blockchain Technical Advisor`,
    link: "https://www.linkedin.com/in/alejandro-sagula-7041815/",
    image: team28Image.src,
  },
  {
    name: "team2",
    title: "Goran Blazevich",
    text: `Game Designer`,
    link: "https://www.linkedin.com/in/goran-blazevich-504707122/",
    image: team29Image.src,
  },
];

console.log(sectionsTeam);

const LandingPageTeamAndADVMobile = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root} id="nfts">
        <Grid container alignItems="center" className={classes.background}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            className={`${classes.bodyContainer}`}
          >
            <Grid item xs={12} className={classes.title}>
              Team & Adv.
            </Grid>
            <Grid item xs={12} className={classes.contentText}>
              <div>
                Sugar Kingdom is a unique game owned by Apes International Group
                Corp.
              </div>
              <div>
                The game has been originally developed from its basics by our
                art and development team.
              </div>
              <div>
                We are a team of professionals working for you, we welcome you
                to know our profiles!
              </div>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <img src={logoMono} alt="" style={{ width: "100%" }} />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LandingPageTeamAndADVMobile;

import { makeStyles, Grid } from "@material-ui/core";
import React, { useState } from "react";
import bgMobile from "../../../assets/images/socialMediaButtons/bgMobile.webp";
import telegramDisabled from "../../../assets/images/socialMediaButtons/telegramDisabled.webp";
import telegramEnabled from "../../../assets/images/socialMediaButtons/telegramEnabled.webp";
import discordDisabled from "../../../assets/images/socialMediaButtons/discordDisabled.webp";
import discordEnabled from "../../../assets/images/socialMediaButtons/discordEnabled.webp";
import twDisabled from "../../../assets/images/socialMediaButtons/twDisabled.webp";
import twEnabled from "../../../assets/images/socialMediaButtons/twEnabled.webp";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    zIndex: 50,
    position: "absolute",
  },
  bgContainer: {
    width: "319px",
    height: "60px",
    backgroundImage: `url(${bgMobile})`,
    backgroundSize: "100% 100%",
    backgroundColor: "transparent",
    left: "50%",
    transform: "translateX(-50%)",
    position: "fixed",
    bottom: "0px",
  },
  container: {
    height: "60px",
    paddingTop: "5px",
  },
  socialMediaImage: {
    width: "54px",
    height: "54px",
  },
  gridContainer: {
    height: "100%",
    padding: "0px 30px",
  },
}));

const SocialMediaButtonsMobile = () => {
  const classes = useStyles();
  const [isHoverImage, setIsHoverImage] = useState(-1);
  return (
    <>
      <div className={classes.root}>
        <div className={classes.bgContainer}>
          <div className={classes.container}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="flex-end"
              className={classes.gridContainer}
            >
              <Grid item>
                <a
                  href="https://t.me/+X3rSQ4M1yYVkNTYx"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    onMouseOver={(e) => setIsHoverImage(1)}
                    onMouseLeave={(e) => setIsHoverImage(-1)}
                    aria-hidden="true"
                    onFocus={() => void 0}
                    src={
                      isHoverImage === 1 ? telegramEnabled : telegramDisabled
                    }
                    alt="telegramDisabled"
                    className={classes.socialMediaImage}
                  />
                </a>
              </Grid>

              <Grid item>
                <a
                  href="https://discord.gg/wevSC3Ty42"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    onMouseOver={(e) => setIsHoverImage(2)}
                    onMouseLeave={(e) => setIsHoverImage(-1)}
                    aria-hidden="true"
                    onFocus={() => void 0}
                    src={isHoverImage === 2 ? discordEnabled : discordDisabled}
                    alt="discordDisabled"
                    className={classes.socialMediaImage}
                  />
                </a>
              </Grid>

              <Grid item>
                <a
                  href="https://twitter.com/SugarKingdomNFT"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    onMouseOver={(e) => setIsHoverImage(3)}
                    onMouseLeave={(e) => setIsHoverImage(-1)}
                    aria-hidden="true"
                    onFocus={() => void 0}
                    src={isHoverImage === 3 ? twEnabled : twDisabled}
                    alt="twDisabled"
                    className={classes.socialMediaImage}
                  />
                </a>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialMediaButtonsMobile;

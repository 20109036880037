// @ts-nocheck
/* eslint jsx-a11y/media-has-caption: 0 */
import { makeStyles, Theme, Grid } from "@material-ui/core";
import React from "react";
import background from "../../../../assets/images/sections/gameView/background.webp";
import CarouselComponent from "./Carousel";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    fontFamily: "GothamRounded",
    background: "black",
  },

  gridContainer: {
    marginTop: "auto",
    marginBottom: "auto",
    width: "100%",
    "&>iframe": {
      width: "100%",
      height: "70%",
    },
  },
  videoVimeo: {
    width: "100vw",
    height: "auto",
  },
  assetContainer: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    height: "100%",
    justifySelf: "center",
    alignSelf: "center",
    justifyContent: "center",
    display: "flex",
  },
  borderContainer: {
    width: "100%",
    height: "fit-content",
    position: "relative",
    marginBottom: "30px",
    "&>img": {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
  },
}));

const LandingPageGameViewMobile = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <div className={classes.assetContainer}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            className={classes.gridContainer}
          >
            <CarouselComponent>
              <video
                width="320"
                height="240"
                controls
                className={classes.videoVimeo}
              >
                <source
                  type="video/mp4"
                  src="https://player.vimeo.com/progressive_redirect/download/680898209/rendition/1080p/sugar_kingdom_-_you_deserve_someting_sweet%21%20%281080p%29.mp4?loc=external&signature=b9adf70d933825906ab4a71527140a081c62da1367ac0124f0cd94bd3a30a6f7"
                />
              </video>
              <video
                width="320"
                height="240"
                controls
                className={classes.videoVimeo}
              >
                <source
                  type="video/mp4"
                  src="https://player.vimeo.com/progressive_redirect/playback/685996692/rendition/1080p?loc=external&signature=e38ba4430f298a0d86fd6e8ab102527d1ba2fc24f5b27c421e630dc8fab1e3cf"
                />
              </video>
              <video
                width="320"
                height="240"
                controls
                className={classes.videoVimeo}
              >
                <source
                  type="video/mp4"
                  src="https://player.vimeo.com/progressive_redirect/playback/676386554/rendition/1080p?loc=external&signature=302b242c65c16a056801ebbd5b221ce5a3b5b41815050d8582d66e4c38512db9"
                />
                {/* <track
                src="captions_en.vtt"
                kind="captions"
                srcLang="en"
                label="english_captions"
              ></track> */}
              </video>
            </CarouselComponent>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default LandingPageGameViewMobile;

import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import background from "../../../../assets/images/header/fondo mobile.png";
import candy1 from "../../../../assets/images/header/candy1 mobile.png";
import candy2 from "../../../../assets/images/header/candy2 mobile.png";

const useStyles = makeStyles((theme) => ({
  background: {
    width: "100vw",
    zIndex: -2,
    height: "100vh",
  },
  container: {
    backgroundColor: "#44e4f9",
    position: "relative",
  },
  containerImages: {
    zIndex: 0,
    maxHeight: "100vh",
  },
  cookie: {
    width: "22%",
    height: "auto",
    position: "absolute",
    bottom: "15%",
    zIndex: 11,
  },
  candy2: {
    width: "30%",
    height: "auto",
    position: "absolute",
    bottom: "15%",
    right: 0,
    zIndex: 11,
  },
}));

const LandingPageHeaderMobile = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container id="header" className={classes.container}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={`${classes.containerImages} clouds`}
        >
          <img
            className={`${classes.cookie} animate__animated customSlideInLeft animate__delay-1s`}
            src={candy1}
            alt="Logo Sugar Kingdom"
          />
          <img
            className={`${classes.candy2} animate__animated customSlideInRight animate__delay-1s`}
            src={candy2}
            alt="Logo Sugar Kingdom"
          />

          <img
            className={classes.background}
            src={background}
            alt="Logo Sugar Kingdom"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default LandingPageHeaderMobile;

import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import logo from "../../../../assets/images/logo_new.webp";
import background from "../../../../assets/images/mobileBar.webp";
import playNowButton from "../../../../assets/images/playNowButton.webp";
import toggleDisabled from "../../../../assets/images/toggleDisabled.webp";
import toggleEnabled from "../../../../assets/images/toggleEnabled.webp";
import MenuDisplay from "../MenuMobile";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "fixed",
    width: "100%",
    zIndex: 1900,
    left: "0px",
  },
  spanPlayNowContainer: {
    position: "fixed",
    top: "0px",
    width: "100%",
  },
  playButtonContainer: {
    backgroundImage: `url(${playNowButton})`,
    backgroundSize: "100% 100%",
    backgroundColor: "transparent",
    marginLeft: "auto",
    marginRight: "auto",
    width: "107px",
    height: "78px",
    lineHeight: "78px",
  },
  logoOneMin: {
    width: "75px",
    position: "relative",
    left: "5px",
    top: "12px",
  },
  toggleButton: {
    width: "40px",
    position: "relative",
    right: "26px",
    top: "14px",
    zIndex: 40,
  },
  containerMenuMobile: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: "82px",
    float: "right",
    backgroundImage: `url(${background})`,
    backgroundSize: "100% 100%",
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  playNowLabel: {
    fontFamily: "GothamRounded",
    fontWeight: 900,
    textAlign: "center",
    display: "block",
    fontSize: "78%",
    color: "white",
    userSelect: "none",
    cursor: "pointer",
  },
}));
interface props {
  handlePageChange: any;
  currentPage: number;
  handleExpandedMenu: any;
  expandedMenu: boolean;
}
const LandingPageMenuMobile = ({
  handlePageChange,
  currentPage,
  handleExpandedMenu,
  expandedMenu,
}: props) => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);

  function openMenu() {
    setMenuOpen(!menuOpen);
  }

  return (
    <div className={classes.root}>
      <MenuDisplay
        menuType="landpage-consumer"
        onClose={openMenu}
        open={menuOpen}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
      ></MenuDisplay>
      <>
        <div className={classes.containerMenuMobile}>
          <span
            onClick={() => handlePageChange(0)}
            onFocus={() => void 0}
            aria-hidden="true"
          >
            <img
              className={classes.logoOneMin}
              src={logo}
              alt="Logo Sugar Kingdom"
            />
          </span>

          <span onClick={openMenu} aria-hidden="true">
            <img
              className={classes.toggleButton}
              src={menuOpen ? toggleEnabled : toggleDisabled}
              alt="Logo Sugar Kingdom"
            />
          </span>
        </div>
        <span className={classes.spanPlayNowContainer}>
          <div className={classes.playButtonContainer}>
            <span className={classes.playNowLabel}>Coming Soon!</span>
          </div>
        </span>
      </>
    </div>
  );
};

export default LandingPageMenuMobile;

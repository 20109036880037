import { Grid, makeStyles, Theme } from "@material-ui/core";
import React, { useRef } from "react";
import team25 from "../../../../assets/images/sections/team/aCarracedo.webp";
import team13 from "../../../../assets/images/sections/team/aferrercrafter.webp";
import team26 from "../../../../assets/images/sections/team/aJaimez.webp";
import team19 from "../../../../assets/images/sections/team/aLorenz.webp";
import team28 from "../../../../assets/images/sections/team/aSagula.webp";
import team18 from "../../../../assets/images/sections/team/aTremosa.webp";
import backCardImage from "../../../../assets/images/sections/team/backCard.webp";
import background from "../../../../assets/images/sections/team/backgroundTeam.webp";
import team16 from "../../../../assets/images/sections/team/christiankuroki.webp";
import team23 from "../../../../assets/images/sections/team/dGonzalez.webp";
import team1 from "../../../../assets/images/sections/team/fLinzuain.webp";
import frontCardImage from "../../../../assets/images/sections/team/frontCard.webp";
import team7 from "../../../../assets/images/sections/team/fsabureau.webp";
import team29 from "../../../../assets/images/sections/team/gblazevich.webp";
import team14 from "../../../../assets/images/sections/team/henrysuarezv.webp";
import team27 from "../../../../assets/images/sections/team/hFoncea.webp";
import team6 from "../../../../assets/images/sections/team/itsshahzaib.webp";
import team11 from "../../../../assets/images/sections/team/jDemarco.webp";
import team12 from "../../../../assets/images/sections/team/jMazzara.webp";
import team24 from "../../../../assets/images/sections/team/jpfernandez.webp";
import team15 from "../../../../assets/images/sections/team/juanadriantapia.webp";
import team21 from "../../../../assets/images/sections/team/kirubakaranreddy.webp";
import logoMono from "../../../../assets/images/sections/team/logomono.png";
import team8 from "../../../../assets/images/sections/team/mCotrofe.webp";
import team2 from "../../../../assets/images/sections/team/mCrespo.webp";
import team3 from "../../../../assets/images/sections/team/mPietrantonio.webp";
import team17 from "../../../../assets/images/sections/team/pekkakelkka.webp";
import team5 from "../../../../assets/images/sections/team/pHernandez.webp";
import team4 from "../../../../assets/images/sections/team/PMoauro.webp";
import team20 from "../../../../assets/images/sections/team/santiagogutierrezquinzan.webp";
import team10 from "../../../../assets/images/sections/team/sLosanovscky.webp";
import team9 from "../../../../assets/images/sections/team/tTrippler.webp";
import team22 from "../../../../assets/images/sections/team/Znamenskiy.webp";
import { useIntersectionObserver } from "../../../../hooks/IntersectionObserver.hook";
import Star from "../star";
import linkedinImage from "../../../../assets/images/sections/team/linkedin.webp";
const linkedingImg = new Image();
linkedingImg.src = linkedinImage;
const linkedin = linkedingImg.src;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    fontFamily: "GothamRounded",
    color: "#4C1A45",
  },
  background: {
    margin: "0 auto",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    padding: "40px",
    height: "100vh",
    zIndex: -2,
    [theme.breakpoints.down("md")]: {
      paddingBottom: "0px",
    },
  },
  imageTeam: {
    margin: "30px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "8vh",
      margin: "0px",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "10vh",
      margin: "0px",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "15vh",
    },
  },
  controlLeft: {
    float: "right",
    height: "42px",
    width: "42px",
    cursor: "pointer",
  },
  controlRight: {
    float: "left",
    height: "42px",
    width: "42px",
    cursor: "pointer",
  },
  indexSelector: {
    margin: "0 12px",
    width: "30%",
    maxWidth: "8vh",
    height: "9px",
    borderRadius: "9px",
    [theme.breakpoints.down("md")]: {
      margin: "0 7px",
      maxWidth: "6vh",
      height: "5px",
    },
  },
  indexContainer: {
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
  indexDisabled: {
    backgroundColor: "white",
  },
  indexEnabled: {
    backgroundColor: "#4C1A45",
  },
  contentText: {
    textAlign: "center",
    paddingBottom: "5px",
    fontWeight: 900,
    [theme.breakpoints.down("md")]: {
      fontSize: "2vh",
      "& p": {
        margin: "0px",
        paddingBottom: "0px",
      },
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "16px",
    },
  },
  title: {
    textAlign: "center",
    display: "block",
    fontWeight: 900,
    [theme.breakpoints.down("md")]: {
      fontSize: "8vh",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "60px",
      paddingBottom: "25px",
    },
  },
  frontCardContainer: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${frontCard})`,
  },
  backCardContainer: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${backCard})`,
  },
  paginatorContainer: {
    maxWidth: "1440px",
  },
  bodyContainer: {
    maxWidth: "1440px",
    marginBottom: "auto",
    marginTop: "auto",
    padding: 0,
  },
  cardBodyContainer: {
    padding: "30px",
    height: "100%",
    [theme.breakpoints.down("lg")]: {
      padding: "0px 7px 0px 7px",
    },
  },
  cardSection1: {
    fontSize: "12px",
    fontWeight: 900,
  },
  cardSection2: {
    paddingTop: "10px",
    fontSize: "23px",
    fontWeight: 900,
    color: "#DC8427",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
      paddingTop: "0px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "18px",
      paddingTop: "5px",
    },
  },
  cardSection3: {
    paddingTop: "10px",
    fontSize: "14px",
    textAlign: "center",
    fontWeight: 900,
    color: "#683A16",
    [theme.breakpoints.down("lg")]: {
      fontSize: "8px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "12px",
    },
  },
  cardSection4: {
    paddingTop: "10px",
    [theme.breakpoints.down("md")]: {
      paddingTop: "5px",
    },
  },
  cardContainer: {
    height: "47vh",
    width: "34vh",
    alignContent: "start",
    backgroundSize: "cover",
    margin: "0px 10px",
    color: "#683A16",
    [theme.breakpoints.down("md")]: {
      height: "41vh",
      width: "29vh",
    },
  },
  descriptionCard: {
    padding: "0px 16px",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5vh",
      padding: "0px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2vh",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "2vh",
    },
  },
  linkedinImage: {
    height: "5vh",
    width: "5vh",
  },
  animationContainer: {
    //animation: "fadeIn 3s ease-in-out",
    animation: "fadeoutfadeinTeam 2s linear",
  },
  textAnimation: {
    opacity: 0,
    animation: "jackInTheBox 2s both 1",
  },
  imageAnimation: {
    opacity: 0,
  },
  cardContainerGroup: {
    margin: "0 10px",
  },
  logomono: {
    textAlign: "center",
    "& a": {
      position: "absolute",
      bottom: "10%",
      left: "50%",
    },
  },
}));

const fci = new Image();
const bci = new Image();
fci.src = frontCardImage;
bci.src = backCardImage;

const frontCard = fci.src;
const backCard = bci.src;

const team1Image = new Image();
team1Image.src = team1;

const team2Image = new Image();
team2Image.src = team2;

const team3Image = new Image();
team3Image.src = team3;

const team4Image = new Image();
team4Image.src = team4;

const team5Image = new Image();
team5Image.src = team5;

const team6Image = new Image();
team6Image.src = team6;

const team7Image = new Image();
team7Image.src = team7;

const team8Image = new Image();
team8Image.src = team8;

const team9Image = new Image();
team9Image.src = team9;

const team10Image = new Image();
team10Image.src = team10;

const team11Image = new Image();
team11Image.src = team11;

const team12Image = new Image();
team12Image.src = team12;
const team13Image = new Image();
team13Image.src = team13;

const team14Image = new Image();
team14Image.src = team14;

const team15Image = new Image();
team15Image.src = team15;

const team16Image = new Image();
team16Image.src = team16;

const team17Image = new Image();
team17Image.src = team17;

const team18Image = new Image();
team18Image.src = team18;

const team19Image = new Image();
team19Image.src = team19;

const team20Image = new Image();
team20Image.src = team20;

const team21Image = new Image();
team21Image.src = team21;

const team22Image = new Image();
team22Image.src = team22;

const team23Image = new Image();
team23Image.src = team23;

const team24Image = new Image();
team24Image.src = team24;

const team25Image = new Image();
team25Image.src = team25;

const team26Image = new Image();
team26Image.src = team26;

const team27Image = new Image();
team27Image.src = team27;

const team28Image = new Image();
team28Image.src = team28;

const team29Image = new Image();
team29Image.src = team29;

const sectionsTeam = [
  {
    cards: [
      {
        name: "team1",
        title: "Francisco Linzuain",
        text: `CEO`,
        link: "https://www.linkedin.com/in/francis-linzuain/",
        image: team1Image.src,
        description:
          "Entrepreneur and lawyer, founder of 2 successful companies previously (with experience in international trade deals with large companies) and former top player of Metin 2 in his youth.",
      },
      {
        name: "team2",
        title: "Marcelo Crespo",
        text: `Art Director`,
        link: "https://www.linkedin.com/in/marcelo-crespo-9162575b/",
        image: team2Image.src,
        description:
          "Marcelo is a talented artist of great experience, who brings his creativity and expertise as the differentiating factor to generate a top quality product, today he also works as Head of Design in the successful blockchain project The Sandbox.",
      },
      {
        name: "team3",
        title: "Mariano Di Pietrantonio",
        text: `Marketing Lead Advisor.`,
        link: "https://www.linkedin.com/in/mariano-di-pietrantonio-0ab4a023/",
        image: team3Image.src,
        description:
          "Mariano used to be the Senior Marketing Manager at MakerDAO, and currently holds the position of Co-Founder and Head Of Strategy at Maker Growth. He is one of the most experienced individuals in the world of blockchain, advising and supporting Sugar Kingdom on the strategies to develop.",
      },
    ],
    name: "Group1",
  },
  {
    cards: [
      {
        name: "team1",
        title: "Pablo Moauro",
        text: `CFO`,
        link: "https://www.linkedin.com/in/pablo-moauro-71a9276a/",
        image: team4Image.src,
        description:
          "Pablo is the Chief Economist of the Argentine Bank Association. Being the head of the monetary policy analysis and definition of strategic issues, his experience ensures the economic sustainability of Sugar Kingdom.",
      },
      {
        name: "team2",
        title: "Prince Hernandez",
        text: `Sr. Front End Developer`,
        link: "https://www.linkedin.com/in/princehernandez/",
        image: team5Image.src,
        description:
          "Prince is an expert Front End Dev. with solid experience in disruptive and emerging technologies such as Web3 and React. With knowledge about the blockchain world. He has also been working with the world leader in Bitcoin ATMs, Coinsource.",
      },
      {
        name: "team3",
        title: "Shahzaib Ali",
        text: `Business Lead Adv.`,
        link: "https://www.linkedin.com/in/itsshahzaib/",
        image: team6Image.src,
        description:
          "Shahzaib is the CEO and Founder of Monsters Clan, one of the most relevant NFTs games in the ecosystem, his valuable advice ensures the success and impact of Sugar Kingdom.",
      },
    ],
    name: "Group2",
  },
  {
    cards: [
      {
        name: "team1",
        title: "Federico Sabureau",
        text: `CTO`,
        link: "https://www.linkedin.com/in/juan-federico-sabureau-515902168/",
        image: team7Image.src,
        description:
          "Federico has extensive experience in managing and coordinating teams, having successfully developed a software factory with international clients, he has a lot of connections.",
      },
      {
        name: "team2",
        title: "Marcelo Cotrofe",
        text: `Sr. Software Engineer`,
        link: "https://www.linkedin.com/in/marcelo-cotrofe-22b15241/",
        image: team8Image.src,
        description:
          "Marcelo is a developer with a lot of experience and background in Unity AR/VR development, in addition to back end technologies such as Java. He previously worked on The Sandbox",
      },
      {
        name: "team3",
        title: "Timo Trippler",
        text: `Fundraising & Commercial Strategy Adv.`,
        link: "https://www.linkedin.com/in/timotrippler/",
        image: team9Image.src,
        description:
          "Timo is the CEO of ADAX.PRO, one of the most promising and validated projects on the Cardano network. He is also an expert in launching ICOs and funding strategies.",
      },
    ],
    name: "Group3",
  },
  {
    cards: [
      {
        name: "team1",
        title: "Santiago Losanovscky",
        text: `CPO`,
        link: "https://www.linkedin.com/in/santiago-p%C3%A9rez-losanovscky-a91877172/",
        image: team10Image.src,
        description:
          "Santiago is a renowned game designer, passionate about the match-3 methodology. For several years, he has specialized in perfecting the application of this game logic, being an expert in this field.",
      },
      {
        name: "team2",
        title: "Juan Ignacio De Marco",
        text: `Sound Designer & Product Development`,
        link: "https://www.linkedin.com/in/juanigdemarco/",
        image: team11Image.src,
        description:
          "Juan Ignacio has great talent when it comes to music, art and sound. He is in charge of choosing and creating all the sounds and voices used in the game, to generate an immersive experience.",
      },
      {
        name: "team3",
        title: "José Ignacio Mazzara",
        text: `Technical Lead Advisor`,
        link: "https://www.linkedin.com/in/imazzara/",
        image: team12Image.src,
        description:
          "José Ignacio is one of the most talented, knowledgeable and experienced people in the blockchain gaming sector. With great skills in analyzing objectives.",
      },
    ],
    name: "Group4",
  },
  {
    cards: [
      {
        name: "team1",
        title: "Arnaldo Corzo Ferrer",
        text: `Unity Developer & Back End Engineer`,
        link: "https://www.linkedin.com/in/aferrercrafter/",
        image: team13Image.src,
        description:
          "Arnaldo is a successful software engineer, with tremendous skills and proven experience in high-impact international projects.",
      },
      {
        name: "team2",
        title: "Henry Suarez",
        text: `Sr. Front End Developer`,
        link: "https://www.linkedin.com/in/henrysuarezv/",
        image: team14Image.src,
        description:
          "Henry is a highly experienced and talented software developer. Having used different technologies, he has incorporated knowledge in multiple areas. His talent is indisputable.",
      },
      {
        name: "team3",
        title: "Juan Tapia",
        text: `Software & Technical Infrastructure Adv.`,
        link: "https://www.linkedin.com/in/juanadriantapia/",
        image: team15Image.src,
        description:
          "Juan is the head of the software and infrastructure area of the pension system in Argentina, also with great experience and passion for blockchain technology, considered an expert in this area.",
      },
    ],
    name: "Group5",
  },
  {
    cards: [
      {
        name: "team1",
        title: "Christian Kuroki",
        text: `Blockchain DevOps Specialist Adv.`,
        link: "https://www.linkedin.com/in/christiankuroki/",
        image: team16Image.src,
        description:
          "Christian is an expert in the field of blockchain, having worked for large-scale projects in recognized companies",
      },
      {
        name: "team2",
        title: "Pekka Kelkka",
        text: `Business Development & Project Growth Adv.`,
        link: "https://www.linkedin.com/in/pekkakelkka/",
        image: team17Image.src,
        description:
          "Pekka is a highly experienced consultant and public speaker, with several years of experience in blockchain and a great talent in planning and executing high-impact digital business strategies.",
      },
      {
        name: "team3",
        title: "Angel Tremosa",
        text: `Sr Technical Artist`,
        link: "https://www.linkedin.com/in/angel-tremosa-86807a33/",
        image: team18Image.src,
        description:
          "Angel is a passionate and very talented artist. He captures his vision in unique pieces that are used within the Sugar Kingdom, giving the user a product of the highest quality.",
      },
    ],
    name: "Group6",
  },
  {
    cards: [
      {
        name: "team1",
        title: "Aixa Ostuvald",
        text: `Artist 2d and Ui Designer`,
        link: "https://www.linkedin.com/in/aixa-ostuvald-lorenz-602554205/",
        image: team19Image.src,
        description:
          "Aixa is a conceptual artist dedicated to her work with various graphic styles, which allows her to have a wide variety of options regarding digital concepts. She highlights her creativity and keen eye by bringing fantastic characters and unique settings to life.",
      },
      {
        name: "team2",
        title: "Santiago Quinzan",
        text: `Ux Ui Designer`,
        link: "https://www.linkedin.com/in/santiagogutierrezquinzan/",
        image: team20Image.src,
        description:
          "Santiago is a talented graphic designer, his professionalism and good practices stand out when applied to any design and product. He is always willing to raise standards and in constant search of new challenges, providing the best quality in each design.",
      },
      {
        name: "team3",
        title: "Kirubakaran Reddy",
        text: `Marketing & Fundraising Strategist`,
        link: "https://www.linkedin.com/in/kirubakaranreddy/",
        image: team21Image.src,
        description:
          "Kirubakaran invested into many promising blockchain projects and advise them in fundraising, business development, community growth and go-to market strategies.",
      },
    ],
    name: "Group7",
  },
  {
    cards: [
      {
        name: "team1",
        title: "Dr. Denis Znamenskiy",
        text: `Business Strategy & Management Adv.`,
        link: "https://www.linkedin.com/in/denisznamenskiy/",
        image: team22Image.src,
        description:
          "Denis is currently the CEO of demole.io (a very successful blockchain gaming project). He is a highly experienced and talented executive.",
      },
      {
        name: "team2",
        title: "Damian Gonzalez",
        text: `Cloud Engineer Manager`,
        link: "https://www.linkedin.com/in/damian-gonzalez-05702676/",
        image: team23Image.src,
        description:
          "Damian is an expert developer in infrastructure and Cloud Dev Ops. He works with technologies like Aws, Terraform and Docker.",
      },
      {
        name: "team3",
        title: "JP Fernandez",
        text: `Business Strategy & Media Adv.`,
        link: "https://www.linkedin.com/in/jp-fernandez/",
        image: team24Image.src,
        description:
          "Juan Pablo is a successful entrepreneur and strategist, with great trajectory in the blockchain industry. With inherence in the areas of communication in several companies of massive impact, such as Cointelegraph.",
      },
    ],
    name: "Group8",
  },
  {
    cards: [
      {
        name: "team1",
        title: "Adrian Pariente",
        text: `Tech Lead`,
        link: "https://www.linkedin.com/in/adrian-pariente-carracedo/",
        image: team25Image.src,
        description:
          "Adrian is a one man army, expert in game development, with experience in the development and successful launch of 4 video games for android and iOs with more than 2.000.000 downloads. Also with experience in Web3 technologies.",
      },
      {
        name: "team2",
        title: "Ariel Jaimez",
        text: `Software Architect & Blockchain Developer`,
        link: "https://www.linkedin.com/in/ariel-jaimez-218b897/",
        image: team26Image.src,
        description:
          "Ariel is a talented back-end engineer and software architect with more than 20 years of experience in managing different areas of development.",
      },
      {
        name: "team3",
        title: "Hugo Foncea",
        text: `Back End Lead`,
        link: "https://www.linkedin.com/in/hugo-foncea-a9403a48/",
        image: team27Image.src,
        description:
          "Hugo is passionate about innovation and new technologies, certified by Microsoft in 1998, he has 30 years of experience developing software for companies in the financial and administrative fields, He is a genius on the back end.",
      },
    ],
    name: "Group9",
  },
  {
    cards: [
      {
        name: "team1",
        title: "Alejandro Sagula",
        text: `Blockchain Technical Advisor`,
        link: "https://www.linkedin.com/in/alejandro-sagula-7041815/",
        image: team28Image.src,
        description:
          "Alejandro is one of the pillars in the technical aspect of the 'Jasper blockchain', a blockchain project of great impact and international relevance.",
      },
      {
        name: "team2",
        title: "Goran Blazevich",
        text: `Game Designer`,
        link: "https://www.linkedin.com/in/goran-blazevich-504707122/",
        image: team29Image.src,
        description:
          "Goran is a young man passionate about the gaming industry, winner of game jams and with a strong commitment to teamwork.",
      },
    ],
    name: "Group10",
  },
];

console.log(sectionsTeam);

const LandingPageTeamAndADV = () => {
  const classes = useStyles();
  const refSection = useRef(null);

  const isSectionVisible = useIntersectionObserver(
    refSection,
    { threshold: 0 },
    true
  );

  return (
    <>
      <div className={classes.root} id="nfts">
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          className={classes.background}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            className={`${classes.bodyContainer} ${
              isSectionVisible ? classes.textAnimation : ""
            }`}
            ref={refSection}
          >
            {isSectionVisible ? (
              <>
                <Grid item xs={12} className={classes.title}>
                  <Star color="red" position="start" />
                  Team & Adv.
                  <Star color="red" position="end" />
                </Grid>
                <Grid item xs={12} className={classes.contentText}>
                  <div>
                    Sugar Kingdom is a unique game owned by Apes International
                    Group Corp.
                  </div>
                  <div>
                    The game has been originally developed from its basics by
                    our art and development team.
                  </div>
                  <div>
                    We are a team of professionals working for you, we welcome
                    you to know our profiles!
                  </div>
                </Grid>
                <Grid item xs={12} className={classes.logomono}>
                  <div>
                    <img src={logoMono} alt="" style={{ width: "50vh" }} />
                    <a
                      href="https://www.linkedin.com/company/apes-group/"
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      <img
                        className={classes.linkedinImage}
                        src={linkedin}
                        alt="imageheader"
                      />
                    </a>
                  </div>
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LandingPageTeamAndADV;

import { Grid, makeStyles, Theme } from "@material-ui/core";
import React, { Fragment, useRef } from "react";
import bg from "../../../../assets/images/sections/welcome/fondo mobile.png";
import CarouselComponent from "./Carousel";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    fontFamily: "GothamRounded",
    color: "#4C1A45",
  },
  background: {
    margin: "0 auto",
    backgroundImage: `url(${bg})`,
    backgroundPositionX: "right",
    backgroundSize: "cover",
    padding: "40px",
    height: "100vh",
    zIndex: -2,
  },
  contentText: {
    fontWeight: 900,
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
      maxWidth: "80%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "20px",
    },
  },
  contentTextLast: {
    fontSize: "30px",
    fontWeight: 900,
    paddingTop: "20px",
    [theme.breakpoints.down("md")]: {
      fontSize: "3vh",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "30px",
    },
  },
  title: {
    fontWeight: 900,
    display: "block",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
      maxWidth: "50%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "60px",
    },
  },
  titleContainer: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: "30px",
      paddingTop: "80px",
      fontSize: "8vh",
    },

    [theme.breakpoints.up("md")]: {
      paddingLeft: "30px",
      paddingTop: "110px",
    },
  },
  textContainer: {
    paddingTop: "32px",
    [theme.breakpoints.down("md")]: {
      paddingTop: "14px",
    },
  },
  titleAnimation: {
    opacity: 1,
    animation: "fadeInDown 2s both 1",
  },
  textAnimation: {
    animation: "fadeInLeft 2s both 1",
  },
  hiddenText: {
    opacity: 0,
  },
  bodyContainer: {
    maxWidth: "1440px",
    height: "100%",
    width: "100%",
    "& .MuiGrid-root": {
      zIndex: 10,
    },
  },
  houses: {
    height: "100%",
    width: "100%",
    zIndex: 1,
    position: "absolute",
    right: 0,
  },
}));

const LandingPageWelcomeMobile = () => {
  const classes = useStyles();

  const refSection = useRef(null);

  const sectionsWelcome = [
    {
      name: "welcome1",
      title: "A Sugar Kingdom!",
      text: [
        `A new place of fantasy, where dreams come true. There are great prizes and lots of fun.`,
        `A king who protects the land of sweetness with his friends.`,
        `It is a constant challenge, candies don't stop falling!`,
        `Time is running out, are you ready?`,
      ],
    },
    {
      name: "welcome2",
      title: "Free to play game and catching experience",
      text: [
        `Play match-3, buy NFTs, compete with other users and be part of the sugar land!`,
        `It's free-to-play, you can try it without risking capital.`,
        `The sooner you start playing, the more tokens you earn.`,
        `With Sugar Kingdom, your time is well rewarded!`,
      ],
    },
    {
      name: "welcome3",
      title: "Give me Sugar!",
      text: [
        `Play to earn in Sugar Kingdom!`,
        `Explore the NFTs, buy card boosters, get limited characters and use them within the platform!`,
        `Do you like sweet things?`,
      ],
    },
  ];

  return (
    <div className={classes.root} id="welcome">
      <img src={bg} className={classes.houses} alt="" />
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        className={classes.background}
      >
        <Grid
          direction="column"
          container
          alignItems="center"
          className={classes.bodyContainer}
        >
          <CarouselComponent>
            {sectionsWelcome.map((section, index) => (
              <Fragment key={index}>
                <Grid item xs={12}>
                  <span ref={refSection} className={`${classes.title}`}>
                    {section.title}
                  </span>
                </Grid>
                <Grid item xs={12} className={`${classes.textContainer}`}>
                  <div className={classes.contentText}>{section.text}</div>
                </Grid>
              </Fragment>
            ))}
          </CarouselComponent>
        </Grid>
      </Grid>
    </div>
  );
};

export default LandingPageWelcomeMobile;

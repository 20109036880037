import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  makeStyles,
  Toolbar,
} from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import React, { useState } from "react";
import { Link } from "react-scroll";
import logo from "../../../../assets/images/logo_new.webp";
import background from "../../../../assets/images/menuBackground.webp";
import miniBackground from "../../../../assets/images/menuMiniBackground.webp";
import expandButton from "../../../../assets/images/expandButton.webp";
import playNowButton from "../../../../assets/images/playNowButton.webp";
import playNowButtonHover from "../../../../assets/images/playNowButtonHover.webp";
import MenuDisplay from "../Menu";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "fixed",
    width: "100%",
    zIndex: 1900,
  },
  appBar: {
    backgroundImage: `url(${background})`,
    backgroundSize: "100% 100%",
    height: "123px",
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  appBarMini: {
    backgroundImage: `url(${miniBackground})`,
    backgroundSize: "100% 100%",
    height: "70px",
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  expandButton: {
    backgroundImage: `url(${expandButton})`,
    backgroundSize: "100% 100%",
    height: "52px",
    width: "62px",
    top: "-6px",
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    display: "block",
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  playButtonContainer: {
    cursor: "pointer",
    backgroundImage: `url(${playNowButton})`,
    backgroundSize: "100% 100%",
    marginRight: "72px",
    width: "190px",
    height: "135px",
    backgroundColor: "transparent",
    [theme.breakpoints.up("lg")]: {
      width: "223px",
      height: "159px",
    },
  },
  playButtonContainerHover: {
    cursor: "pointer",
    backgroundImage: `url(${playNowButtonHover})`,
    backgroundSize: "100% 100%",
    marginRight: "72px",
    width: "190px",
    height: "135px",
    backgroundColor: "transparent",
    [theme.breakpoints.up("lg")]: {
      width: "223px",
      height: "159px",
    },
  },
  toolbar: {
    padding: "0px",
    maxHeight: "123px",
    [theme.breakpoints.up("lg")]: {},
  },
  logoLeft: {},
  logoOne: {
    width: "120px",
    position: "relative",
    [theme.breakpoints.up("lg")]: {
      width: "142px",
      marginLeft: "30%",
      top: "28px",
    },
  },
  logoOneMobile: {},
  logoOneMin: {
    width: "120px",
    position: "relative",
    padding: "10px 0px 0px 40px",
  },
  containerMenuDesktop: {
    display: "flex",
    alignItems: "start",
    justifyContent: "space-between",
    width: "100%",
  },
  containerMenuMobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  buttonText: {
    fontFamily: "GothamRounded",
    color: "white",
    padding: "54px 15px",
    fontSize: "13px",
    cursor: "pointer",
    textAlign: "center",
    [theme.breakpoints.up("xl")]: {
      fontSize: "16px",
      padding: "54px 40px",
    },
  },
  buttonLogo: {
    cursor: "pointer",
    padding: "0px 60px 0px 15px",
  },
  spacer: {
    padding: "10px",
    [theme.breakpoints.up("md")]: {
      padding: "10px",
    },
  },
  imagePlayNow: {
    position: "relative",
  },
  buttonIconMenu: {
    marginRight: "30px",
  },
  menuItemActive: {
    "& span": {
      color: "#4C1A45",
      fontWeight: 700,
      textDecoration: "underline #4C1A45 3px solid",
    },
  },
  menuItemActiveIndividual: {
    color: "#4C1A45",
    fontWeight: 700,
  },
  linksContainer: {
    display: "inline-flex",
    "& a": {
      position: "relative",
    },
  },
  playNowLabel: {
    fontFamily: "GothamRounded",
    fontWeight: 900,
    textAlign: "center",
    position: "relative",
    display: "block",
    fontSize: "24px",
    marginTop: "35%",
    userSelect: "none",
    cursor: "pointer",
    [theme.breakpoints.up("xl")]: {
      fontSize: "29px",
      marginTop: "28%",
    },
  },
}));
interface props {
  handlePageChange: any;
  currentPage: number;
  handleExpandedMenu: any;
  expandedMenu: boolean;
}
const LandingPageMenu = ({
  handlePageChange,
  currentPage,
  handleExpandedMenu,
  expandedMenu,
}: props) => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isHoveringMenuItem, setIsHoveringMenuItem] = useState("hero");
  const [playNowHover, setPlayNowHover] = useState(false);
  const sectionList = [
    { key: "welcome", value: "Welcome!", page: 1 },
    { key: "gameView", value: "Game View", page: 2 },
    { key: "ourGoal", value: "Our Goal", page: 3 },
    { key: "nfts", value: "NFTs", page: 4 },
    { key: "team", value: "Team & ADV", page: 5 },
    // { key: "roadMap", value: "Road Map", page: 6 },
    { key: "partners", value: "Partners", page: 6 },
    { key: "docs", value: "Documents", page: 7 },
  ];

  function openMenu() {
    setMenuOpen(!menuOpen);
  }

  const openGame = () => {
    window.open("https://play.sugarkingdom.io/", "_blank", "noopener");
  };

  return (
    <div className={classes.root}>
      <MenuDisplay
        menuType="landpage-consumer"
        onClose={openMenu}
        open={menuOpen}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
      ></MenuDisplay>
      <>
        <Hidden lgUp>
          <AppBar position="static" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
              <div className={classes.containerMenuMobile}>
                <Link
                  activeClass="active"
                  to="hero"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                >
                  <span
                    onClick={() => handlePageChange(0)}
                    onFocus={() => void 0}
                    aria-hidden="true"
                  >
                    <img
                      className={classes.logoOneMin}
                      src={logo}
                      alt="Logo Sugar Kingdom"
                    />
                  </span>
                </Link>
                <IconButton
                  onClick={openMenu}
                  disableRipple
                  className="button-icon-content mt-0 mb-0 pr-0 pt-0 pb-0"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                >
                  <Menu className={`${classes.buttonIconMenu} button-icon`} />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
        </Hidden>

        <Hidden xsDown smDown mdDown>
          <AppBar
            position="static"
            className={
              currentPage === 0 || expandedMenu === true
                ? classes.appBar
                : classes.appBarMini
            }
          >
            <Toolbar className={classes.toolbar}>
              {(currentPage === 0 || expandedMenu === true) && (
                <div
                  className={classes.containerMenuDesktop}
                  onMouseLeave={(e) => handleExpandedMenu(false)}
                  onFocus={() => void 0}
                  aria-hidden="true"
                >
                  <div className={classes.linksContainer}>
                    <span
                      onClick={() => handlePageChange(0)}
                      onFocus={() => void 0}
                      aria-hidden="true"
                      className={classes.buttonLogo}
                    >
                      <img
                        className={classes.logoOne}
                        src={logo}
                        alt="Logo Sugar Kingdom"
                      />
                    </span>
                    {sectionList.map((section) => (
                      <span
                        key={section.key}
                        onFocus={() => void 0}
                        aria-hidden="true"
                        onMouseOver={(e) => setIsHoveringMenuItem(section.key)}
                        onMouseLeave={(e) => setIsHoveringMenuItem("")}
                        onClick={() => handlePageChange(section.page)}
                        className={`${classes.buttonText} ${
                          isHoveringMenuItem === section.key ||
                          currentPage === section.page
                            ? classes.menuItemActiveIndividual
                            : ""
                        }`}
                      >
                        {section.value}
                      </span>
                    ))}
                  </div>
                  <Box
                    className={
                      playNowHover
                        ? classes.playButtonContainerHover
                        : classes.playButtonContainer
                    }
                    onMouseOver={(e) => setPlayNowHover(true)}
                    onMouseLeave={(e) => setPlayNowHover(false)}
                    onClick={() => openGame()}
                  >
                    <span className={classes.playNowLabel} aria-hidden="true">
                      PLAY NOW
                    </span>
                  </Box>
                </div>
              )}
              {currentPage !== 0 && !expandedMenu && (
                <div
                  onMouseOver={(e) => handleExpandedMenu(true)}
                  onFocus={() => void 0}
                  aria-hidden="true"
                  className={classes.expandButton}
                ></div>
              )}
            </Toolbar>
          </AppBar>
        </Hidden>
      </>
    </div>
  );
};

export default LandingPageMenu;

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Grid, makeStyles, Theme } from "@material-ui/core";
import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";
import background from "../../../../assets/images/sections/nfts/background.webp";
import nfts1 from "../../../../assets/images/sections/nfts/nfts1.webp";
import nfts2 from "../../../../assets/images/sections/nfts/nfts6.webp";
import nfts3 from "../../../../assets/images/sections/nfts/nfts3.png";
import nfts4 from "../../../../assets/images/sections/nfts/nfts4.png";
import nfts5 from "../../../../assets/images/sections/nfts/nfts5.webp";
import nfts6 from "../../../../assets/images/sections/nfts/nfts6.png";
import nfts7 from "../../../../assets/images/sections/nfts/nfts7.png";
import nfts8 from "../../../../assets/images/sections/nfts/nfts8.png";
import nfts9 from "../../../../assets/images/sections/nfts/nfts9.webp";
import nfts10 from "../../../../assets/images/sections/nfts/nfts10.png";
import nfts11 from "../../../../assets/images/sections/nfts/nfts11.png";
import nfts12 from "../../../../assets/images/sections/nfts/nfts12.webp";
import nfts13 from "../../../../assets/images/sections/nfts/nfts13.webp";
import nfts14 from "../../../../assets/images/sections/nfts/nfts14.webp";
import nfts15 from "../../../../assets/images/sections/nfts/nfts15.webp";
import nfts16 from "../../../../assets/images/sections/nfts/nfts16.webp";
import nfts17 from "../../../../assets/images/sections/nfts/nfts17.webp";
import nfts18 from "../../../../assets/images/sections/nfts/nfts18.webp";
import nfts1Back from "../../../../assets/images/sections/nfts/nfts1Back.webp";
import nfts2Back from "../../../../assets/images/sections/nfts/nfts6Back.webp";
import nfts3Back from "../../../../assets/images/sections/nfts/nfts3Back.webp";
import nfts4Back from "../../../../assets/images/sections/nfts/nfts4Back.webp";
import nfts5Back from "../../../../assets/images/sections/nfts/nfts5Back.webp";
import nfts6Back from "../../../../assets/images/sections/nfts/nfts2Back.webp";
import nfts7Back from "../../../../assets/images/sections/nfts/nfts7Back.webp";
import nfts8Back from "../../../../assets/images/sections/nfts/nfts8Back.webp";
import nfts9Back from "../../../../assets/images/sections/nfts/nfts9Back.webp";
import nfts10Back from "../../../../assets/images/sections/nfts/nfts10Back.webp";
import nfts11Back from "../../../../assets/images/sections/nfts/nfts11Back.webp";
import nfts12Back from "../../../../assets/images/sections/nfts/nfts12Back.webp";
import nfts13Back from "../../../../assets/images/sections/nfts/nfts13Back.webp";
import nfts14Back from "../../../../assets/images/sections/nfts/nfts14Back.webp";
import nfts15Back from "../../../../assets/images/sections/nfts/nfts15Back.webp";
import nfts16Back from "../../../../assets/images/sections/nfts/nfts16Back.webp";
import nfts17Back from "../../../../assets/images/sections/nfts/nfts17Back.webp";
import nfts18Back from "../../../../assets/images/sections/nfts/nfts18Back.webp";
import CarouselComponent from "./Carousel";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    fontFamily: "GothamRounded",
    color: "#4C1A45",
  },
  background: {
    margin: "0 auto",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    padding: "40px 40px 80px 40px",
    paddingTop: 80,
    height: "100vh",
    zIndex: -2,
  },
  contentText: {
    textAlign: "center",
    fontWeight: 900,
    maxWidth: "80%",
    marginTop: 25,
    fontSize: "10px",
  },
  title: {
    marginTop: 30,
    textAlign: "center",
    display: "block",
    fontWeight: 900,
    fontSize: "24px",
  },
  imageF: {
    width: "80%",
  },
  imageB: {
    width: "80%",
  },
}));

const image1Nft = new Image();
const image1NftBack = new Image();
image1Nft.src = nfts1;
image1NftBack.src = nfts1Back;

const image2Nft = new Image();
const image2NftBack = new Image();
image2Nft.src = nfts2;
image2NftBack.src = nfts2Back;

const image3Nft = new Image();
const image3NftBack = new Image();
image3Nft.src = nfts3;
image3NftBack.src = nfts3Back;

const image4Nft = new Image();
const image4NftBack = new Image();
image4Nft.src = nfts4;
image4NftBack.src = nfts4Back;

const image5Nft = new Image();
const image5NftBack = new Image();
image5Nft.src = nfts5;
image5NftBack.src = nfts5Back;

const image6Nft = new Image();
const image6NftBack = new Image();
image6Nft.src = nfts6;
image6NftBack.src = nfts6Back;

const image7Nft = new Image();
const image7NftBack = new Image();
image7Nft.src = nfts7;
image7NftBack.src = nfts7Back;

const image8Nft = new Image();
const image8NftBack = new Image();
image8Nft.src = nfts8;
image8NftBack.src = nfts8Back;

const image9Nft = new Image();
const image9NftBack = new Image();
image9Nft.src = nfts9;
image9NftBack.src = nfts9Back;

const image10Nft = new Image();
const image10NftBack = new Image();
image10Nft.src = nfts10;
image10NftBack.src = nfts10Back;

const image11Nft = new Image();
const image11NftBack = new Image();
image11Nft.src = nfts11;
image11NftBack.src = nfts11Back;

const image12Nft = new Image();
const image12NftBack = new Image();
image12Nft.src = nfts12;
image12NftBack.src = nfts12Back;

const image13Nft = new Image();
const image13NftBack = new Image();
image13Nft.src = nfts13;
image13NftBack.src = nfts13Back;

const image14Nft = new Image();
const image14NftBack = new Image();
image14Nft.src = nfts14;
image14NftBack.src = nfts14Back;

const image15Nft = new Image();
const image15NftBack = new Image();
image15Nft.src = nfts15;
image15NftBack.src = nfts15Back;

const image16Nft = new Image();
const image16NftBack = new Image();
image16Nft.src = nfts16;
image16NftBack.src = nfts16Back;

const image17Nft = new Image();
const image17NftBack = new Image();
image17Nft.src = nfts17;
image17NftBack.src = nfts17Back;

const image18Nft = new Image();
const image18NftBack = new Image();
image18Nft.src = nfts18;
image18NftBack.src = nfts18Back;

const LandingNftsMobile = () => {
  const classes = useStyles();
  const sectionsNfts = [
    {
      name: "nfts1",
      title: "NFTS 1",
      text: `Lorem ipsum dolor sit amet`,
      image: image1Nft.src,
      backImage: image1NftBack.src,
    },
    {
      name: "nfts2",
      title: "NFTS 2",
      text: `Lorem ipsum dolor sit amet`,
      image: image2Nft.src,
      backImage: image2NftBack.src,
    },
    {
      name: "nfts3",
      title: "NFTS 3",
      text: `Lorem ipsum dolor sit amet`,
      image: image3Nft.src,
      backImage: image3NftBack.src,
    },

    {
      name: "nfts4",
      title: "NFTS 4",
      text: `Lorem ipsum dolor sit amet`,
      image: image4Nft.src,
      backImage: image4NftBack.src,
    },
    {
      name: "nfts5",
      title: "NFTS 5",
      text: `Lorem ipsum dolor sit amet`,
      image: image5Nft.src,
      backImage: image5NftBack.src,
    },
    {
      name: "nfts6",
      title: "NFTS 6",
      text: `Lorem ipsum dolor sit amet`,
      image: image6Nft.src,
      backImage: image6NftBack.src,
    },

    {
      name: "nfts7",
      title: "NFTS 7",
      text: `Lorem ipsum dolor sit amet`,
      image: image7Nft.src,
      backImage: image7NftBack.src,
    },

    {
      name: "nfts8",
      title: "NFTS 8",
      text: `Lorem ipsum dolor sit amet`,
      image: image8Nft.src,
      backImage: image8NftBack.src,
    },
    {
      name: "nfts9",
      title: "NFTS 9",
      text: `Lorem ipsum dolor sit amet`,
      image: image9Nft.src,
      backImage: image9NftBack.src,
    },
    {
      name: "nfts10",
      title: "NFTS 10",
      text: `Lorem ipsum dolor sit amet`,
      image: image10Nft.src,
      backImage: image10NftBack.src,
    },
    {
      name: "nfts11",
      title: "NFTS 11",
      text: `Lorem ipsum dolor sit amet`,
      image: image11Nft.src,
      backImage: image11NftBack.src,
    },
    {
      name: "nfts12",
      title: "NFTS 12",
      text: `Lorem ipsum dolor sit amet`,
      image: image12Nft.src,
      backImage: image12NftBack.src,
    },
    {
      name: "nfts13",
      title: "NFTS 13",
      text: `Lorem ipsum dolor sit amet`,
      image: image13Nft.src,
      backImage: image13NftBack.src,
    },
    {
      name: "nfts14",
      title: "NFTS 14",
      text: `Lorem ipsum dolor sit amet`,
      image: image14Nft.src,
      backImage: image14NftBack.src,
    },
    {
      name: "nfts15",
      title: "NFTS 15",
      text: `Lorem ipsum dolor sit amet`,
      image: image15Nft.src,
      backImage: image15NftBack.src,
    },
    {
      name: "nfts16",
      title: "NFTS 16",
      text: `Lorem ipsum dolor sit amet`,
      image: image16Nft.src,
      backImage: image16NftBack.src,
    },
    {
      name: "nfts17",
      title: "NFTS 17",
      text: `Lorem ipsum dolor sit amet`,
      image: image17Nft.src,
      backImage: image17NftBack.src,
    },
    {
      name: "nfts18",
      title: "NFTS 18",
      text: `Lorem ipsum dolor sit amet`,
      image: image18Nft.src,
      backImage: image18NftBack.src,
    },
  ];

  const [flippedIndex, setFlippedIndex] = useState(-1);

  return (
    <>
      <div className={classes.root} id="nfts">
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          className={classes.background}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} className={classes.title}>
              Fantastic cards!
            </Grid>
            <Grid item xs={12} className={classes.contentText}>
              They are limited and you can do many things with them. Earn up to
              240 times thanks to your new NFT!
            </Grid>
          </Grid>

          <CarouselComponent scrollButtons>
            {sectionsNfts.map((section, index) => (
              <ReactCardFlip
                key={index}
                isFlipped={index === flippedIndex}
                flipDirection="horizontal"
                flipSpeedBackToFront={1}
                flipSpeedFrontToBack={1}
              >
                <img
                  aria-hidden="true"
                  src={section.image}
                  alt="imageheader"
                  className={classes.imageF}
                  onClick={() => setFlippedIndex(index)}
                />
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                <img
                  src={section.backImage}
                  alt="imageheader"
                  className={classes.imageB}
                  onClick={() => setFlippedIndex(-1)}
                />
              </ReactCardFlip>
            ))}
          </CarouselComponent>
        </Grid>
      </div>
    </>
  );
};

export default LandingNftsMobile;

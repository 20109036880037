import { Grid, makeStyles, Theme } from "@material-ui/core";
import React, { Fragment } from "react";
import background from "../../../../assets/images/sections/ourGoal/backgroundMobile.webp";
import goal1char from "../../../../assets/images/sections/ourGoal/goal1char.webp";
import goal1header from "../../../../assets/images/sections/ourGoal/goal1header.png";
import goal2char from "../../../../assets/images/sections/ourGoal/goal2char.webp";
import goal2header from "../../../../assets/images/sections/ourGoal/goal2header.png";
import goal3char from "../../../../assets/images/sections/ourGoal/goal3char.webp";
import goal3header from "../../../../assets/images/sections/ourGoal/goal3header.png";
import CarouselComponent from "./Carousel";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    fontFamily: "GothamRounded",
    color: "white",
  },
  background: {
    margin: "0 auto",
    backgroundImage: `url(${background})`,
    backgroundPositionX: "right",
    backgroundSize: "cover",
    height: "100vh",
    zIndex: -2,
    padding: "40px",
  },
  bodyContainer: {
    marginTop: "100px",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    fontSize: "10px",
  },
  imageHeader: {
    width: "20%",
  },
  imageChar: {
    width: "100%",
  },
  title: {
    fontSize: "24px",
    marginTop: 25,
  },
}));
const image1Char = new Image();
const image1Header = new Image();

const image2Char = new Image();
const image2Header = new Image();

const image3Char = new Image();
const image3Header = new Image();

image1Header.src = goal1header;
image1Char.src = goal1char;

image2Header.src = goal2header;
image2Char.src = goal2char;

image3Header.src = goal3header;
image3Char.src = goal3char;

const LandingOurGoalMobile = () => {
  const classes = useStyles();
  const sectionsGoals = [
    {
      name: "goal1",
      title: "Crypto for everyone",
      text: [
        `Sugar Kingdom is a fantastic match-3 game with metaverse features, 
        suitable for all ages, gender neutral, where everyone enjoys. `,
        `A sweet world is coming!`,
      ],
      imageHeader: image1Header.src,
      imageChar: image1Char.src,
    },
    {
      name: "goal2",
      title: "Much more than a game",
      text: [
        `We want to democratize access and distribution of crypto games. 
        Let's be the creators of the new generation of video games.`,
        `The change is today. It is here. It is now`,
      ],
      imageHeader: image2Header.src,
      imageChar: image2Char.src,
    },
    {
      name: "goal3",
      title: "Long term vision",
      text: [
        `We share your vision. That's why we are designing a different gaming experience. 
        We are 100% user focused with zero barriers to entry. 
        We want Sugar Kingdom to be the new paradigm in crypto 
        gaming, where users are the winners!`,
      ],
      imageHeader: image3Header.src,
      imageChar: image3Char.src,
    },
  ];

  return (
    <>
      <div className={classes.root} id="ourGoals">
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          className={classes.background}
        >
          <Grid
            direction="row"
            container
            alignItems="center"
            justifyContent="center"
            className={`${classes.bodyContainer}`}
          >
            <CarouselComponent>
              {sectionsGoals.map((section, index) => (
                <Fragment key={index}>
                  <Grid
                    direction="row"
                    container
                    alignItems="center"
                    justifyContent="center"
                    className={classes.contentContainer}
                  >
                    <img
                      src={section.imageHeader}
                      alt="imageheader"
                      className={classes.imageHeader}
                    />
                    <span className={classes.title}>{section.title}</span>

                    {section.text.map((p, index) => (
                      <p key={index}>{p}</p>
                    ))}
                    <img
                      src={section.imageChar}
                      alt="imageheader"
                      className={classes.imageChar}
                    />
                  </Grid>
                </Fragment>
              ))}
            </CarouselComponent>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LandingOurGoalMobile;

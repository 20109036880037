import { makeStyles, Grid } from "@material-ui/core";
import React, { useState } from "react";
import bg from "../../../assets/images/socialMediaButtons/bg.webp";
import telegramDisabled from "../../../assets/images/socialMediaButtons/telegramDisabled.webp";
import telegramEnabled from "../../../assets/images/socialMediaButtons/telegramEnabled.webp";
import discordDisabled from "../../../assets/images/socialMediaButtons/discordDisabled.webp";
import discordEnabled from "../../../assets/images/socialMediaButtons/discordEnabled.webp";
import twDisabled from "../../../assets/images/socialMediaButtons/twDisabled.webp";
import twEnabled from "../../../assets/images/socialMediaButtons/twEnabled.webp";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "70px",
    height: "320px",
    position: "fixed",
    float: "right",
    right: "0px",
    top: "33vh",
    backgroundImage: `url(${bg})`,
    zIndex: 99999,
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      width: "50px",
      height: "225px",
    },
  },
  container: {
    width: "59px",
    float: "right",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "45px",
    },
  },
  socialMediaImage: {
    width: "54px",
    height: "54px",
    [theme.breakpoints.down("sm")]: {
      width: "40px",
      height: "40px",
    },
  },
  gridContainer: {
    height: "100%",
    padding: "30px 0px 30px 0px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 0px 10px 0px",
    },
  },
}));

const SocialMediaButtons = () => {
  const classes = useStyles();
  const [isHoverImage, setIsHoverImage] = useState(-1);
  return (
    <>
      <div className={classes.root}>
        <div className={classes.container}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="space-around"
            className={classes.gridContainer}
          >
            <Grid item>
              <a
                href="https://t.me/+X3rSQ4M1yYVkNTYx"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  onMouseOver={(e) => setIsHoverImage(1)}
                  onMouseLeave={(e) => setIsHoverImage(-1)}
                  aria-hidden="true"
                  onFocus={() => void 0}
                  src={isHoverImage === 1 ? telegramEnabled : telegramDisabled}
                  alt="telegramDisabled"
                  className={classes.socialMediaImage}
                />
              </a>
            </Grid>

            <Grid item>
              <a
                href="https://discord.gg/wevSC3Ty42"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  onMouseOver={(e) => setIsHoverImage(2)}
                  onMouseLeave={(e) => setIsHoverImage(-1)}
                  aria-hidden="true"
                  onFocus={() => void 0}
                  src={isHoverImage === 2 ? discordEnabled : discordDisabled}
                  alt="discordDisabled"
                  className={classes.socialMediaImage}
                />
              </a>
            </Grid>

            <Grid item>
              <a
                href="https://twitter.com/SugarKingdomNFT"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  onMouseOver={(e) => setIsHoverImage(3)}
                  onMouseLeave={(e) => setIsHoverImage(-1)}
                  aria-hidden="true"
                  onFocus={() => void 0}
                  src={isHoverImage === 3 ? twEnabled : twDisabled}
                  alt="twDisabled"
                  className={classes.socialMediaImage}
                />
              </a>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default SocialMediaButtons;

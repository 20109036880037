import { Grid, makeStyles, Theme } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import ReactCardFlip from "react-card-flip";
import nextDisabled from "../../../../assets/images/nextDisabled.webp";
import nextEnabled from "../../../../assets/images/nextEnabled.webp";
import prevDisabled from "../../../../assets/images/prevDisabled.webp";
import prevEnabled from "../../../../assets/images/prevEnabled.webp";
import background from "../../../../assets/images/sections/nfts/background.webp";
import nfts1 from "../../../../assets/images/sections/nfts/nfts1.webp";
import nfts10 from "../../../../assets/images/sections/nfts/nfts10.png";
import nfts10Back from "../../../../assets/images/sections/nfts/nfts10Back.webp";
import nfts11 from "../../../../assets/images/sections/nfts/nfts11.png";
import nfts11Back from "../../../../assets/images/sections/nfts/nfts11Back.webp";
import nfts12 from "../../../../assets/images/sections/nfts/nfts12.webp";
import nfts12Back from "../../../../assets/images/sections/nfts/nfts12Back.webp";
import nfts13 from "../../../../assets/images/sections/nfts/nfts13.webp";
import nfts13Back from "../../../../assets/images/sections/nfts/nfts13Back.webp";
import nfts14 from "../../../../assets/images/sections/nfts/nfts14.webp";
import nfts14Back from "../../../../assets/images/sections/nfts/nfts14Back.webp";
import nfts15 from "../../../../assets/images/sections/nfts/nfts15.webp";
import nfts15Back from "../../../../assets/images/sections/nfts/nfts15Back.webp";
import nfts16 from "../../../../assets/images/sections/nfts/nfts16.webp";
import nfts16Back from "../../../../assets/images/sections/nfts/nfts16Back.webp";
import nfts17 from "../../../../assets/images/sections/nfts/nfts17.webp";
import nfts17Back from "../../../../assets/images/sections/nfts/nfts17Back.webp";
import nfts18 from "../../../../assets/images/sections/nfts/nfts18.webp";
import nfts18Back from "../../../../assets/images/sections/nfts/nfts18Back.webp";
import nfts1Back from "../../../../assets/images/sections/nfts/nfts1Back.webp";
import nfts6 from "../../../../assets/images/sections/nfts/nfts6.png";
import nfts6Back from "../../../../assets/images/sections/nfts/nfts2Back.webp";
import nfts3 from "../../../../assets/images/sections/nfts/nfts3.png";
import nfts3Back from "../../../../assets/images/sections/nfts/nfts3Back.webp";
import nfts4 from "../../../../assets/images/sections/nfts/nfts4.png";
import nfts4Back from "../../../../assets/images/sections/nfts/nfts4Back.webp";
import nfts5 from "../../../../assets/images/sections/nfts/nfts5.webp";
import nfts5Back from "../../../../assets/images/sections/nfts/nfts5Back.webp";
import nfts2 from "../../../../assets/images/sections/nfts/nfts6.webp";
import nfts2Back from "../../../../assets/images/sections/nfts/nfts6Back.webp";
import nfts7 from "../../../../assets/images/sections/nfts/nfts7.png";
import nfts7Back from "../../../../assets/images/sections/nfts/nfts7Back.webp";
import nfts8 from "../../../../assets/images/sections/nfts/nfts8.png";
import nfts8Back from "../../../../assets/images/sections/nfts/nfts8Back.webp";
import nfts9 from "../../../../assets/images/sections/nfts/nfts9.webp";
import nfts9Back from "../../../../assets/images/sections/nfts/nfts9Back.webp";
import { useIntersectionObserver } from "../../../../hooks/IntersectionObserver.hook";
import Star from "../star";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    fontFamily: "GothamRounded",
    color: "#4C1A45",
  },
  background: {
    margin: "0 auto",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    padding: "40px",
    height: "100vh",
    zIndex: -2,
  },
  imageNfts: {
    padding: "0px",
    [theme.breakpoints.down("md")]: {
      maxHeight: "30vh",
    },
    [theme.breakpoints.up("md")]: {
      maxHeight: "40vh",
    },
    [theme.breakpoints.up("lg")]: {
      maxHeight: "45vh",
    },
  },
  animationRight: {
    animation: "lightSpeedInRight 2s ease-out",
  },
  controlLeft: {
    float: "right",
    height: "42px",
    width: "42px",
    cursor: "pointer",
  },
  controlRight: {
    float: "left",
    height: "42px",
    width: "42px",
    cursor: "pointer",
  },
  indexSelector: {
    margin: "0 12px",
    width: "30%",
    maxWidth: "8vh",
    height: "9px",
    borderRadius: "9px",
    [theme.breakpoints.down("md")]: {
      margin: "0 7px",
      maxWidth: "6vh",
      height: "5px",
    },
  },
  indexContainer: {
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
  indexDisabled: {
    backgroundColor: "white",
  },
  indexEnabled: {
    backgroundColor: "#4C1A45",
  },
  contentText: {
    textAlign: "center",
    fontWeight: 900,
    [theme.breakpoints.down("md")]: {
      fontSize: "2vh",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "16px",
    },
  },
  title: {
    textAlign: "center",
    display: "block",
    fontWeight: 900,
    [theme.breakpoints.down("md")]: {
      fontSize: "8vh",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "60px",
      paddingBottom: "25px",
    },
  },
  cardContainer: {
    paddingTop: "15px",
    [theme.breakpoints.down("md")]: {
      paddingTop: "0px",
    },
  },
  paginatorContainer: {
    maxWidth: "1440px",
  },
  bodyContainer: {
    maxWidth: "1440px",
    marginBottom: "auto",
    marginTop: "auto",
    padding: 0,
  },
  animationContainer: {
    animation: "fadeIn 3s ease-in-out",
    justifyContent: "space-evenly",
  },
  textAnimation: {
    opacity: 0,
    animation: "fadeIn 2s both 1",
  },
}));

const image1Nft = new Image();
const image1NftBack = new Image();
image1Nft.src = nfts1;
image1NftBack.src = nfts1Back;

const image2Nft = new Image();
const image2NftBack = new Image();
image2Nft.src = nfts2;
image2NftBack.src = nfts2Back;

const image3Nft = new Image();
const image3NftBack = new Image();
image3Nft.src = nfts3;
image3NftBack.src = nfts3Back;

const image4Nft = new Image();
const image4NftBack = new Image();
image4Nft.src = nfts4;
image4NftBack.src = nfts4Back;

const image5Nft = new Image();
const image5NftBack = new Image();
image5Nft.src = nfts5;
image5NftBack.src = nfts5Back;

const image6Nft = new Image();
const image6NftBack = new Image();
image6Nft.src = nfts6;
image6NftBack.src = nfts6Back;

const image7Nft = new Image();
const image7NftBack = new Image();
image7Nft.src = nfts7;
image7NftBack.src = nfts7Back;

const image8Nft = new Image();
const image8NftBack = new Image();
image8Nft.src = nfts8;
image8NftBack.src = nfts8Back;

const image9Nft = new Image();
const image9NftBack = new Image();
image9Nft.src = nfts9;
image9NftBack.src = nfts9Back;

const image10Nft = new Image();
const image10NftBack = new Image();
image10Nft.src = nfts10;
image10NftBack.src = nfts10Back;

const image11Nft = new Image();
const image11NftBack = new Image();
image11Nft.src = nfts11;
image11NftBack.src = nfts11Back;

const image12Nft = new Image();
const image12NftBack = new Image();
image12Nft.src = nfts12;
image12NftBack.src = nfts12Back;

const image13Nft = new Image();
const image13NftBack = new Image();
image13Nft.src = nfts13;
image13NftBack.src = nfts13Back;

const image14Nft = new Image();
const image14NftBack = new Image();
image14Nft.src = nfts14;
image14NftBack.src = nfts14Back;

const image15Nft = new Image();
const image15NftBack = new Image();
image15Nft.src = nfts15;
image15NftBack.src = nfts15Back;

const image16Nft = new Image();
const image16NftBack = new Image();
image16Nft.src = nfts16;
image16NftBack.src = nfts16Back;

const image17Nft = new Image();
const image17NftBack = new Image();
image17Nft.src = nfts17;
image17NftBack.src = nfts17Back;

const image18Nft = new Image();
const image18NftBack = new Image();
image18Nft.src = nfts18;
image18NftBack.src = nfts18Back;

const LandingNfts = () => {
  const classes = useStyles();
  const sectionsNfts = [
    {
      cards: [
        {
          name: "nfts1",
          title: "NFTS 1",
          text: `Lorem ipsum dolor sit amet`,
          image: image1Nft.src,
          backImage: image1NftBack.src,
        },
        {
          name: "nfts2",
          title: "NFTS 2",
          text: `Lorem ipsum dolor sit amet`,
          image: image2Nft.src,
          backImage: image2NftBack.src,
        },
        {
          name: "nfts3",
          title: "NFTS 3",
          text: `Lorem ipsum dolor sit amet`,
          image: image3Nft.src,
          backImage: image3NftBack.src,
        },
      ],
      name: "Group1",
    },
    {
      cards: [
        {
          name: "nfts4",
          title: "NFTS 4",
          text: `Lorem ipsum dolor sit amet`,
          image: image4Nft.src,
          backImage: image4NftBack.src,
        },
        {
          name: "nfts5",
          title: "NFTS 5",
          text: `Lorem ipsum dolor sit amet`,
          image: image5Nft.src,
          backImage: image5NftBack.src,
        },
        {
          name: "nfts6",
          title: "NFTS 6",
          text: `Lorem ipsum dolor sit amet`,
          image: image6Nft.src,
          backImage: image6NftBack.src,
        },
      ],
      name: "Group2",
    },
    {
      cards: [
        {
          name: "nfts7",
          title: "NFTS 7",
          text: `Lorem ipsum dolor sit amet`,
          image: image7Nft.src,
          backImage: image7NftBack.src,
        },

        {
          name: "nfts8",
          title: "NFTS 8",
          text: `Lorem ipsum dolor sit amet`,
          image: image8Nft.src,
          backImage: image8NftBack.src,
        },
        {
          name: "nfts9",
          title: "NFTS 9",
          text: `Lorem ipsum dolor sit amet`,
          image: image9Nft.src,
          backImage: image9NftBack.src,
        },
      ],
      name: "Group3",
    },
    {
      cards: [
        {
          name: "nfts10",
          title: "NFTS 10",
          text: `Lorem ipsum dolor sit amet`,
          image: image10Nft.src,
          backImage: image10NftBack.src,
        },
        {
          name: "nfts11",
          title: "NFTS 11",
          text: `Lorem ipsum dolor sit amet`,
          image: image11Nft.src,
          backImage: image11NftBack.src,
        },
        {
          name: "nfts12",
          title: "NFTS 12",
          text: `Lorem ipsum dolor sit amet`,
          image: image12Nft.src,
          backImage: image12NftBack.src,
        },
      ],
      name: "Group4",
    },
    {
      cards: [
        {
          name: "nfts13",
          title: "NFTS 13",
          text: `Lorem ipsum dolor sit amet`,
          image: image13Nft.src,
          backImage: image13NftBack.src,
        },
        {
          name: "nfts14",
          title: "NFTS 14",
          text: `Lorem ipsum dolor sit amet`,
          image: image14Nft.src,
          backImage: image14NftBack.src,
        },
        {
          name: "nfts15",
          title: "NFTS 15",
          text: `Lorem ipsum dolor sit amet`,
          image: image15Nft.src,
          backImage: image15NftBack.src,
        },
      ],
      name: "Group5",
    },
    {
      cards: [
        {
          name: "nfts16",
          title: "NFTS 16",
          text: `Lorem ipsum dolor sit amet`,
          image: image16Nft.src,
          backImage: image16NftBack.src,
        },
        {
          name: "nfts17",
          title: "NFTS 17",
          text: `Lorem ipsum dolor sit amet`,
          image: image17Nft.src,
          backImage: image17NftBack.src,
        },
        {
          name: "nfts18",
          title: "NFTS 18",
          text: `Lorem ipsum dolor sit amet`,
          image: image18Nft.src,
          backImage: image18NftBack.src,
        },
      ],
      name: "Group6",
    },
  ];
  const [activeIndex, setActiveIndex] = useState(0);
  const [flip, setFlip] = useState("");
  const [classNameSet, setClassName] = useState("");
  const refSection = useRef(null);

  useEffect(() => {
    setClassName(classes.animationContainer);
  }, [activeIndex]);

  const updateIndex = (newIndex: number) => {
    setClassName("");
    if (newIndex >= 0 && newIndex < sectionsNfts.length) {
      updateFlip("");
      setActiveIndex(newIndex);
      setTimeout(() => {
        setClassName(classes.animationContainer);
      }, 500);
    } else if (newIndex >= sectionsNfts.length) {
      updateFlip("");
      setActiveIndex(0);
    }
  };
  const updateFlip = (name: string) => {
    setFlip(name);
  };

  const isSectionVisible = useIntersectionObserver(
    refSection,
    { threshold: 0 },
    true
  );

  const [hoverOn, setHoverOn] = useState("");

  return (
    <>
      <div
        className={classes.root}
        id="nfts"
        onPointerEnter={(e) => updateFlip("")}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          className={classes.background}
          onPointerEnter={(e) => updateFlip("")}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            ref={refSection}
            onPointerEnter={(e) => updateFlip("")}
            className={`${classes.bodyContainer} ${
              isSectionVisible ? classes.textAnimation : ""
            }`}
          >
            {isSectionVisible ? (
              <>
                <Grid item xs={12} className={classes.title}>
                  <Star color="green" position="start" />
                  Fantastic cards!
                  <Star color="green" position="end" />
                </Grid>
                <Grid item xs={12} className={classes.contentText}>
                  They are limited and you can do many things with them. Earn up
                  to 240 times thanks to your new NFT!
                </Grid>
              </>
            ) : null}
          </Grid>

          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            style={{ maxWidth: "1440px" }}
            className={classNameSet}
            onPointerEnter={(e) => updateFlip("")}
          >
            {sectionsNfts[activeIndex].cards.map((card, i) => (
              <Grid
                item
                sm={3}
                container
                key={`${card.name} cardNfts`}
                className={classes.cardContainer}
              >
                <ReactCardFlip
                  isFlipped={flip === card.name}
                  flipDirection="horizontal"
                  flipSpeedBackToFront={1}
                  flipSpeedFrontToBack={1}
                >
                  <div>
                    <img
                      onPointerEnter={(e) => updateFlip(card.name)}
                      onFocus={() => void 0}
                      aria-hidden="true"
                      src={card.image}
                      alt="imageheader"
                      className={classes.imageNfts}
                    />
                  </div>
                  <div
                    onPointerOut={(e) => updateFlip("")}
                    onFocus={() => void 0}
                    aria-hidden="true"
                  >
                    <img
                      src={card.backImage}
                      alt="imageheader"
                      className={classes.imageNfts}
                    />
                  </div>
                </ReactCardFlip>
              </Grid>
            ))}
          </Grid>

          <Grid
            direction="row"
            container
            alignItems="center"
            justifyContent="center"
            className={classes.paginatorContainer}
            onPointerEnter={(e) => updateFlip("")}
          >
            <Grid item xs={3} sm={1}>
              <img
                src={hoverOn === "left" ? prevEnabled : prevDisabled}
                alt="prev"
                className={classes.controlLeft}
                onClick={() => updateIndex(activeIndex - 1)}
                aria-hidden="true"
                onMouseEnter={() => setHoverOn("left")}
                onMouseLeave={() => setHoverOn("")}
              />
            </Grid>
            <Grid item xs={6} sm={10}>
              <div className={classes.indexContainer}>
                {sectionsNfts.map((section, i) => (
                  <div
                    key={`goalKey${i}`}
                    className={`${classes.indexSelector} ${
                      i === activeIndex
                        ? classes.indexEnabled
                        : classes.indexDisabled
                    }`}
                    onClick={() => updateIndex(i)}
                    aria-hidden="true"
                  ></div>
                ))}
              </div>
            </Grid>
            <Grid item xs={3} sm={1}>
              <img
                src={hoverOn === "right" ? nextEnabled : nextDisabled}
                alt="next"
                className={classes.controlRight}
                onClick={() => updateIndex(activeIndex + 1)}
                aria-hidden="true"
                onMouseEnter={() => setHoverOn("right")}
                onMouseLeave={() => setHoverOn("")}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LandingNfts;

import { Grid, makeStyles, Theme } from "@material-ui/core";
import React, { useState } from "react";
import ACKNOWLEDGER1 from "../../../../assets/images/sections/partners/ACKNOWLEDGER1.webp";
import ACKNOWLEDGER2 from "../../../../assets/images/sections/partners/ACKNOWLEDGER2.webp";
import ALPHABLOCKZ1 from "../../../../assets/images/sections/partners/ALPHABLOCKZ1.webp";
import ALPHABLOCKZ2 from "../../../../assets/images/sections/partners/ALPHABLOCKZ2.webp";
import ALPHAVENTURES1 from "../../../../assets/images/sections/partners/ALPHAVENTURES1.webp";
import ALPHAVENTURES2 from "../../../../assets/images/sections/partners/ALPHAVENTURES2.webp";
import ALPHA_HUNT_COLOR1 from "../../../../assets/images/sections/partners/ALPHA_HUNT_COLOR1.webp";
import ALPHA_HUNT_COLOR2 from "../../../../assets/images/sections/partners/ALPHA_HUNT_COLOR2.webp";
import aMM_VENTURES1 from "../../../../assets/images/sections/partners/aMM_VENTURES1.webp";
import aMM_VENTURES2 from "../../../../assets/images/sections/partners/aMM_VENTURES2.webp";
import AVG1 from "../../../../assets/images/sections/partners/AVG1.webp";
import AVG2 from "../../../../assets/images/sections/partners/AVG2.webp";
import AZA_GROUPS_COLOR1 from "../../../../assets/images/sections/partners/AZA_GROUPS_COLOR1.webp";
import AZA_GROUPS_COLOR2 from "../../../../assets/images/sections/partners/AZA_GROUPS_COLOR2.webp";
import BABYLONS1 from "../../../../assets/images/sections/partners/BABYLONS1.webp";
import BABYLONS2 from "../../../../assets/images/sections/partners/BABYLONS2.webp";
import background from "../../../../assets/images/sections/partners/backgroundMobile.webp";
import BBS_FINANCE1 from "../../../../assets/images/sections/partners/BBS_FINANCE1.webp";
import BBS_FINANCE2 from "../../../../assets/images/sections/partners/BBS_FINANCE2.webp";
import BLAGO1 from "../../../../assets/images/sections/partners/BLAGO1.webp";
import BLAGO2 from "../../../../assets/images/sections/partners/BLAGO2.webp";
import BLUEWHEEL1 from "../../../../assets/images/sections/partners/BLUEWHEEL1.webp";
import BLUEWHEEL2 from "../../../../assets/images/sections/partners/BLUEWHEEL2.webp";
import BROTHERHOOD1 from "../../../../assets/images/sections/partners/BROTHERHOOD1.webp";
import BROTHERHOOD2 from "../../../../assets/images/sections/partners/BROTHERHOOD2.webp";
import COINFABRIK1 from "../../../../assets/images/sections/partners/COINFABRIK1.webp";
import COINFABRIK2 from "../../../../assets/images/sections/partners/COINFABRIK2.webp";
import CRYPTOBLADES1 from "../../../../assets/images/sections/partners/CRYPTOBLADES1.webp";
import CRYPTOBLADES2 from "../../../../assets/images/sections/partners/CRYPTOBLADES2.webp";
import CRYPTOFOMO1 from "../../../../assets/images/sections/partners/CRYPTOFOMO1.webp";
import CRYPTOFOMO2 from "../../../../assets/images/sections/partners/CRYPTOFOMO2.webp";
import CRYPTOPIA1 from "../../../../assets/images/sections/partners/CRYPTOPIA1.webp";
import CRYPTOPIA2 from "../../../../assets/images/sections/partners/CRYPTOPIA2.webp";
import DAOLAND1 from "../../../../assets/images/sections/partners/DAOLAND1.webp";
import DAOLAND2 from "../../../../assets/images/sections/partners/DAOLAND2.webp";
import DaoLaunch1 from "../../../../assets/images/sections/partners/DaoLaunch1.webp";
import DaoLaunch2 from "../../../../assets/images/sections/partners/DaoLaunch2.webp";
import DCI1 from "../../../../assets/images/sections/partners/DCI1.webp";
import DCI2 from "../../../../assets/images/sections/partners/DCI2.webp";
import Deprizio1 from "../../../../assets/images/sections/partners/Deprizio1.webp";
import Deprizio2 from "../../../../assets/images/sections/partners/Deprizio2.webp";
import DESERT1 from "../../../../assets/images/sections/partners/DESERT1.webp";
import DESERT2 from "../../../../assets/images/sections/partners/DESERT2.webp";
import DUCKDAO1 from "../../../../assets/images/sections/partners/DUCKDAO1.webp";
import DUCKDAO2 from "../../../../assets/images/sections/partners/DUCKDAO2.webp";
import DuckStarter1 from "../../../../assets/images/sections/partners/DuckStarter1.webp";
import DuckStarter2 from "../../../../assets/images/sections/partners/DuckStarter2.webp";
import EMPIRE_VC1 from "../../../../assets/images/sections/partners/EMPIRE_VC1.webp";
import EMPIRE_VC2 from "../../../../assets/images/sections/partners/EMPIRE_VC2.webp";
import ERAX1 from "../../../../assets/images/sections/partners/ERAX1.webp";
import ERAX2 from "../../../../assets/images/sections/partners/ERAX2.webp";
import FALCON2 from "../../../../assets/images/sections/partners/FALCON COLOR.png";
import FALCON1 from "../../../../assets/images/sections/partners/FALCON GREYSCALE.png";
import GAMEFI1 from "../../../../assets/images/sections/partners/GAMEFI1.webp";
import GAMEFI2 from "../../../../assets/images/sections/partners/GAMEFI2.webp";
import GameStation1 from "../../../../assets/images/sections/partners/GameStation1.webp";
import GameStation2 from "../../../../assets/images/sections/partners/GameStation2.webp";
import GEMPAD1 from "../../../../assets/images/sections/partners/GEMPAD1.webp";
import GEMPAD2 from "../../../../assets/images/sections/partners/GEMPAD2.webp";
import GOTBIT1 from "../../../../assets/images/sections/partners/GOTBIT1.webp";
import GOTBIT2 from "../../../../assets/images/sections/partners/GOTBIT2.webp";
import HALVINGS1 from "../../../../assets/images/sections/partners/HALVINGS1.webp";
import HALVINGS2 from "../../../../assets/images/sections/partners/HALVINGS2.webp";
import IXIR1 from "../../../../assets/images/sections/partners/IXIR1.webp";
import IXIR2 from "../../../../assets/images/sections/partners/IXIR2.webp";
import KOISTARTER1 from "../../../../assets/images/sections/partners/KOISTARTER1.webp";
import KOISTARTER2 from "../../../../assets/images/sections/partners/KOISTARTER2.webp";
import KOMMUNITAS1 from "../../../../assets/images/sections/partners/KOMMUNITAS1.webp";
import KOMMUNITAS2 from "../../../../assets/images/sections/partners/KOMMUNITAS2.webp";
import LEGION_VENTURES1 from "../../../../assets/images/sections/partners/LEGION_VENTURES1.webp";
import LEGION_VENTURES2 from "../../../../assets/images/sections/partners/LEGION_VENTURES2.webp";
import MAY_CLUB1 from "../../../../assets/images/sections/partners/MAY_CLUB1.webp";
import MAY_CLUB2 from "../../../../assets/images/sections/partners/MAY_CLUB2.webp";
import MM_VENTURES1 from "../../../../assets/images/sections/partners/MM_VENTURES1.webp";
import MM_VENTURES2 from "../../../../assets/images/sections/partners/MM_VENTURES2.webp";
import MOONSTARTER1 from "../../../../assets/images/sections/partners/MOONSTARTER1.webp";
import MOONSTARTER2 from "../../../../assets/images/sections/partners/MOONSTARTER2.webp";
import MYSTERIA1 from "../../../../assets/images/sections/partners/MYSTERIA1.webp";
import MYSTERIA2 from "../../../../assets/images/sections/partners/MYSTERIA2.webp";
import NFTPAD1 from "../../../../assets/images/sections/partners/NFTPAD1.webp";
import NFTPAD2 from "../../../../assets/images/sections/partners/NFTPAD2.webp";
import PROSTARTER1 from "../../../../assets/images/sections/partners/PROSTARTER1.webp";
import PROSTARTER2 from "../../../../assets/images/sections/partners/PROSTARTER2.webp";
import Ronins1 from "../../../../assets/images/sections/partners/Ronins1.webp";
import Ronins2 from "../../../../assets/images/sections/partners/Ronins2.webp";
import SafeLaunch1 from "../../../../assets/images/sections/partners/SafeLaunch1.webp";
import SafeLaunch2 from "../../../../assets/images/sections/partners/SafeLaunch2.webp";
import SCORPIO2 from "../../../../assets/images/sections/partners/SCORPIO2.webp";
import SCORPIOVC1 from "../../../../assets/images/sections/partners/SCORPIOVC1.webp";
import SCOTTYBEAM from "../../../../assets/images/sections/partners/SCOTTYBEAM.webp";
import SKYGUARDIANS1 from "../../../../assets/images/sections/partners/SKYGUARDIANS1.webp";
import SKYGUARDIANS2 from "../../../../assets/images/sections/partners/SKYGUARDIANS2.webp";
import SOLIDITY1 from "../../../../assets/images/sections/partners/SOLIDITY1.webp";
import SOLIDITY2 from "../../../../assets/images/sections/partners/SOLIDITY2.webp";
import SPINTOP1 from "../../../../assets/images/sections/partners/SPINTOP1.webp";
import SPINTOP2 from "../../../../assets/images/sections/partners/SPINTOP2.webp";
import SYNAPSE_NETWORK1 from "../../../../assets/images/sections/partners/SYNAPSE_NETWORK1.webp";
import SYNAPSE_NETWORK2 from "../../../../assets/images/sections/partners/SYNAPSE_NETWORK2.webp";
import TRUSTFI1 from "../../../../assets/images/sections/partners/TRUSTFI1.webp";
import TRUSTFI2 from "../../../../assets/images/sections/partners/TRUSTFI2.webp";
import UNREALCAPITAL1 from "../../../../assets/images/sections/partners/UNREALCAPITAL1.webp";
import UNREALCAPITAL2 from "../../../../assets/images/sections/partners/UNREALCAPITAL2.webp";
import YASAR1 from "../../../../assets/images/sections/partners/YASAR1.webp";
import YASAR2 from "../../../../assets/images/sections/partners/YASAR2.webp";
import ZBS1 from "../../../../assets/images/sections/partners/ZBS1.webp";
import ZBS2 from "../../../../assets/images/sections/partners/ZBS2.webp";
import ZEPHYRUS1 from "../../../../assets/images/sections/partners/ZEPHYRUS1.webp";
import ZEPHYRUS2 from "../../../../assets/images/sections/partners/ZEPHYRUS2.webp";
import CarouselComponent from "../landingPageMobile/Carousel";

interface ILogo {
  srcColor: string;
  srcGray: string;
  url: string;
}

const logos: ILogo[] = [
  {
    srcGray: ALPHA_HUNT_COLOR1,
    srcColor: ALPHA_HUNT_COLOR2,
    url: "https://alphahunt.club/",
  },
  {
    srcGray: AVG1,
    srcColor: AVG2,
    url: "https://autoventure.group/",
  },

  {
    srcGray: AZA_GROUPS_COLOR1,
    srcColor: AZA_GROUPS_COLOR2,
    url: "https://azagroups.co/",
  },
  {
    srcGray: BBS_FINANCE1,
    srcColor: BBS_FINANCE2,
    url: "https://bbsfinance.be/",
  },
  {
    srcGray: BLUEWHEEL1,
    srcColor: BLUEWHEEL2,
    url: "https://www.bluewheelcapital.com/",
  },
  {
    srcGray: BROTHERHOOD1,
    srcColor: BROTHERHOOD2,
    url: "https://brotherhood.ventures/",
  },
  {
    srcColor: CRYPTOFOMO2,
    srcGray: CRYPTOFOMO1,
    url: "https://www.youtube.com/c/CryptoFOMO",
  },
  {
    srcColor: DCI2,
    srcGray: DCI1,
    url: "https://dutchcryptoinvestors.com/",
  },
  {
    srcColor: EMPIRE_VC2,
    srcGray: EMPIRE_VC1,
    url: "https://empirecapitalventures.com/",
  },
  {
    srcColor: GAMEFI2,
    srcGray: GAMEFI1,
    url: "https://gameficapital.net/",
  },
  {
    srcColor: HALVINGS2,
    srcGray: HALVINGS1,
    url: "https://halvingscapital.com/",
  },
  {
    srcColor: YASAR2,
    srcGray: YASAR1,
    url: "https://www.yasarcorp.com/",
  },

  {
    srcColor: KOMMUNITAS2,
    srcGray: KOMMUNITAS1,
    url: "https://www.kommunitas.net/",
  },
  {
    srcColor: LEGION_VENTURES2,
    srcGray: LEGION_VENTURES1,
    url: "https://www.legion.ventures/",
  },
];
const logos2: ILogo[] = [
  {
    srcColor: MAY_CLUB2,
    srcGray: MAY_CLUB1,
    url: "https://t.me/+dFcrDZsy_pFhMDA6",
  },
  {
    srcColor: MM_VENTURES2,
    srcGray: MM_VENTURES1,
    url: "http://alphablockz.io",
  },
  {
    srcColor: aMM_VENTURES2,
    srcGray: aMM_VENTURES1,
    url: "",
  },
  {
    srcColor: MYSTERIA2,
    srcGray: MYSTERIA1,
    url: "https://mysteriacapital.com/",
  },
  {
    srcColor: PROSTARTER2,
    srcGray: PROSTARTER1,
    url: "https://prostarter.io/",
  },
  {
    srcColor: SYNAPSE_NETWORK2,
    srcGray: SYNAPSE_NETWORK1,
    url: "https://synapse.network/",
  },
  {
    srcColor: ZBS2,
    srcGray: ZBS1,
    url: "https://zbs.capital/",
  },
  {
    srcColor: GOTBIT2,
    srcGray: GOTBIT1,
    url: "https://gotbit.io/",
  },
  {
    srcColor: ALPHABLOCKZ2,
    srcGray: ALPHABLOCKZ1,
    url: "https://alphablockz.io/https://alphablockz.io/",
  },
  {
    srcColor: DUCKDAO2,
    srcGray: DUCKDAO1,
    url: "https://duckdao.io/",
  },
  {
    srcColor: BLAGO2,
    srcGray: BLAGO1,
    url: "https://blago.capital/",
  },
  {
    srcColor: CRYPTOBLADES2,
    srcGray: CRYPTOBLADES1,
    url: "https://www.cryptoblades.io/",
  },
  {
    srcColor: CRYPTOPIA2,
    srcGray: CRYPTOPIA1,
    url: "https://www.youtube.com/c/Cryptopia1",
  },
  {
    srcColor: DESERT2,
    srcGray: DESERT1,
    url: " https://desertwhales.com/",
  },
  {
    srcColor: KOISTARTER2,
    srcGray: KOISTARTER1,
    url: "https://www.koistarter.io/",
  },
];

const logos3: ILogo[] = [
  {
    srcColor: SCOTTYBEAM,
    srcGray: SCOTTYBEAM,
    url: "https://scottybeam.io/",
  },
  {
    srcColor: COINFABRIK2,
    srcGray: COINFABRIK1,
    url: "https://www.coinfabrik.com",
  },
  {
    srcColor: SOLIDITY2,
    srcGray: SOLIDITY1,
    url: "https://solidity.finance/",
  },
  {
    srcColor: MOONSTARTER2,
    srcGray: MOONSTARTER1,
    url: "https://moonstarter.net/",
  },
  {
    srcColor: SafeLaunch2,
    srcGray: SafeLaunch1,
    url: "https://safelaunch.io/",
  },
  {
    srcColor: Deprizio2,
    srcGray: Deprizio1,
    url: "https://www.deprizioadvisory.com/",
  },
  {
    srcColor: DuckStarter2,
    srcGray: DuckStarter1,
    url: "https://duckstarter.io/",
  },
  {
    srcColor: GameStation2,
    srcGray: GameStation1,
    url: "https://www.gamestation.io/",
  },
  {
    srcColor: Ronins2,
    srcGray: Ronins1,
    url: "",
  },
  {
    srcColor: DaoLaunch2,
    srcGray: DaoLaunch1,
    url: "https://daolaunch.net/",
  },
  {
    srcColor: ACKNOWLEDGER2,
    srcGray: ACKNOWLEDGER1,
    url: "https://acknoledger.com/",
  },
  {
    srcColor: ALPHAVENTURES2,
    srcGray: ALPHAVENTURES1,
    url: "https://www.alphaventure.xyz/",
  },
  {
    srcColor: BABYLONS2,
    srcGray: BABYLONS1,
    url: "https://babylons.io/",
  },
  {
    srcColor: DAOLAND2,
    srcGray: DAOLAND1,
    url: "https://daoland.io",
  },
  {
    srcColor: ERAX2,
    srcGray: ERAX1,
    url: "https://www.erax.io/#/",
  },
];

const logos4: ILogo[] = [
  {
    srcColor: GEMPAD2,
    srcGray: GEMPAD1,
    url: "https://gempad.app/presales",
  },
  {
    srcColor: IXIR2,
    srcGray: IXIR1,
    url: "https://ixirswap.com/",
  },
  {
    srcColor: NFTPAD2,
    srcGray: NFTPAD1,
    url: "https://nftpad.fi/",
  },
  {
    srcColor: SCORPIO2,
    srcGray: SCORPIOVC1,
    url: "http://scorpio.vc/Investment_en.html",
  },
  {
    srcColor: SKYGUARDIANS2,
    srcGray: SKYGUARDIANS1,
    url: "",
  },
  {
    srcColor: SPINTOP2,
    srcGray: SPINTOP1,
    url: "https://spintop.network/",
  },
  {
    srcColor: TRUSTFI2,
    srcGray: TRUSTFI1,
    url: "https://www.trustfi.org/",
  },
  {
    srcGray: ZEPHYRUS1,
    srcColor: ZEPHYRUS2,
    url: "https://zephyruscapital.com/incubator/",
  },
  {
    srcColor: UNREALCAPITAL2,
    srcGray: UNREALCAPITAL1,
    url: "https://ucapital.vc/ ",
  },
  {
    srcColor: FALCON2,
    srcGray: FALCON1,
    url: "https://twitter.com/falcon_CapitaI",
  },
];
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    fontFamily: "GothamRounded",
    color: "white",
  },
  container: {
    margin: "0 auto",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    padding: "90px 20px 0px 20px",
    height: "100vh",
  },
  sectionContent: {
    justifyContent: "center",
    margin: "auto",
  },
  images: {
    transition: "all .5s ease-in-out",
    padding: "0px",
    maxHeight: "8vh",
  },
  title: {
    fontWeight: 900,
    fontFamily: "GothamRounded",
    color: "#4C1A45",
    fontSize: "24px",
  },
  textAnimation: {
    animation: "fadeIn 2s both 1",
  },
  hoverEffect: {
    transform: "scale(1.5)",
  },
}));

const LandingPagePartnersMobile = () => {
  const classes = useStyles();
  const [clickedList, setClickedList] = useState<string[]>([]);

  const hasBeenClicked = (name: string, index: number) => {
    const keyName = `${name}${index}`;

    return clickedList.includes(keyName);
  };

  const openUrl = (url: string | undefined, index: number, name: string) => {
    const keyName = `${name}${index}`;
    if (!clickedList.includes(keyName)) {
      setClickedList([...clickedList, keyName]);
    }

    if (url) {
      window.open(url, "_blank", "noopener noreferrer");
    }
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.container}>
          <Grid container alignItems="center" justifyContent="center">
            <span className={classes.title}>Partners</span>
          </Grid>

          <CarouselComponent>
            <Grid
              className={classes.sectionContent}
              container
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              {logos.map(({ srcColor, srcGray, url }, index) => (
                <Grid item key={`containerLogoPartner_ ${index}`}>
                  <div
                    onClick={() => openUrl(url, index, "logo1")}
                    aria-hidden="true"
                  >
                    <img
                      className={`${classes.images} ${hasBeenClicked(
                        "logo1",
                        index
                      )}`}
                      src={hasBeenClicked("logo1", index) ? srcColor : srcGray}
                      alt=""
                      key={`${index} image`}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
            <Grid
              className={classes.sectionContent}
              container
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              {logos2.map(({ srcColor, srcGray, url }, index) => (
                <Grid item key={`containerLogo2Partner_ ${index + 10}`}>
                  <div
                    onClick={() => openUrl(url, index, "logo2")}
                    aria-hidden="true"
                  >
                    <img
                      className={`${classes.images} ${hasBeenClicked(
                        "logo2",
                        index
                      )}`}
                      src={hasBeenClicked("logo2", index) ? srcColor : srcGray}
                      alt=""
                      key={`${index + 10} image`}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>

            <Grid
              className={classes.sectionContent}
              container
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              {logos3.map(({ srcColor, srcGray, url }, index) => (
                <Grid item key={`containerLogoPartner_ ${index + 20}`}>
                  <div
                    onClick={() => openUrl(url, index, "logo3")}
                    aria-hidden="true"
                  >
                    <img
                      className={`${classes.images} ${hasBeenClicked(
                        "logo3",
                        index
                      )}`}
                      src={hasBeenClicked("logo3", index) ? srcColor : srcGray}
                      alt=""
                      key={`${index + 20} image`}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
            <Grid
              className={classes.sectionContent}
              container
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              {logos4.map(({ srcColor, srcGray, url }, index) => (
                <Grid item key={`containerLogoPartner_ ${index + 30}`}>
                  <div
                    onClick={() => openUrl(url, index, "logo4")}
                    aria-hidden="true"
                  >
                    <img
                      className={`${classes.images} ${hasBeenClicked(
                        "logo4",
                        index
                      )}`}
                      src={hasBeenClicked("logo4", index) ? srcColor : srcGray}
                      alt=""
                      key={`${index + 30} image`}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          </CarouselComponent>
        </div>
      </div>
    </>
  );
};

export default LandingPagePartnersMobile;

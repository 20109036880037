import { Grid, makeStyles, Theme } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import nextDisabled from "../../../../assets/images/nextDisabled.webp";
import nextEnabled from "../../../../assets/images/nextEnabled.webp";
import prevDisabled from "../../../../assets/images/prevDisabled.webp";
import prevEnabled from "../../../../assets/images/prevEnabled.webp";
import background from "../../../../assets/images/sections/ourGoal/background.webp";
import goal1char from "../../../../assets/images/sections/ourGoal/goal1char.webp";
import goal1header from "../../../../assets/images/sections/ourGoal/goal1header.png";
import goal2char from "../../../../assets/images/sections/ourGoal/goal2char.webp";
import goal2header from "../../../../assets/images/sections/ourGoal/goal2header.png";
import goal3char from "../../../../assets/images/sections/ourGoal/goal3char.webp";
import goal3header from "../../../../assets/images/sections/ourGoal/goal3header.png";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    fontFamily: "GothamRounded",
    color: "white",
  },
  background: {
    margin: "0 auto",
    backgroundImage: `url(${background})`,
    backgroundPositionX: "right",
    backgroundSize: "cover",
    paddingTop: 40,
    paddingBottom: 40,
    height: "100vh",
    zIndex: -2,
  },
  animationRight: {
    animation: "lightSpeedInRight 2s ease-out",
  },
  controlLeft: {
    float: "right",
    height: "42px",
    width: "42px",
    cursor: "pointer",
  },
  controlRight: {
    float: "left",
    height: "42px",
    width: "42px",
    cursor: "pointer",
  },
  indexSelector: {
    margin: "0 12px",
    width: "30%",
    maxWidth: "223px",
    height: "9px",
    borderRadius: "9px",
  },
  indexContainer: {
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
  indexDisabled: {
    backgroundColor: "white",
  },
  indexEnabled: {
    backgroundColor: "#4C1A45",
  },
  contentText: {
    textAlign: "center",
    fontWeight: 900,
    [theme.breakpoints.down("md")]: {
      fontSize: "2vh",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "20px",
    },
  },
  title: {
    textAlign: "center",
    display: "block",
    fontWeight: 900,
    [theme.breakpoints.down("md")]: {
      fontSize: "8vh",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "50px",
    },
  },
  imageHeader: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "146px",
    [theme.breakpoints.down("md")]: {
      width: "12vh",
    },
    [theme.breakpoints.up("md")]: {
      width: "18vh",
    },
    [theme.breakpoints.up("lg")]: {
      width: "20vh",
    },
  },
  imageChar: {
    width: "80vh",
    display: "block",
    position: "absolute",
    bottom: 0,
    left: "3vh",
    [theme.breakpoints.down("md")]: {
      width: "70vh",
    },
  },
  bodyContainer: {
    marginTop: "auto !important",
    marginBottom: "auto !important",
    background:
      "linear-gradient(90deg, rgba(112,233,231,0) 0%, rgba(112,233,231,0.7) 19.79%, rgba(170,166,223,1) 44.58%, rgba(229,97,214,0.6) 65.41%, rgba(253,70,211,0.7) 98.74%)",
  },
  paginatorContainer: {
    zIndex: 9,
    maxWidth: "1440px",
  },
  contentContainer: {
    maxWidth: "1440px",
    height: "70vh",
    [theme.breakpoints.down("xl")]: {
      margin: "0px 10px 0px 10px",
    },
  },
  rightContainer: {
    zIndex: 9,
    [theme.breakpoints.down("lg")]: {
      padding: "0 16vh",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0 120px",
    },
  },
  animationContainer: {
    animation: "fadeInRight 1.5s ease-in-out",
  },
  firstAnimation: {
    animation: "fadeInDown 2s both 1",
  },
  imageAnimation: {
    animation: "fadeInLeft 2s both 1",
  },
}));
const image1Char = new Image();
const image1Header = new Image();

const image2Char = new Image();
const image2Header = new Image();

const image3Char = new Image();
const image3Header = new Image();

image1Header.src = goal1header;
image1Char.src = goal1char;

image2Header.src = goal2header;
image2Char.src = goal2char;

image3Header.src = goal3header;
image3Char.src = goal3char;

const LandingOurGoal = () => {
  const classes = useStyles();
  const sectionsGoals = [
    {
      name: "goal1",
      title: "Crypto for everyone",
      text: [
        `Sugar Kingdom is a fantastic match-3 game with metaverse features, 
        suitable for all ages, gender neutral, where everyone enjoys. `,
        `A sweet world is coming!`,
      ],
      imageHeader: image1Header.src,
      imageChar: image1Char.src,
    },
    {
      name: "goal2",
      title: "Much more than a game",
      text: [
        `We want to democratize access and distribution of crypto games. 
        Let's be the creators of the new generation of video games.`,
        `The change is today. It is here. It is now`,
      ],
      imageHeader: image2Header.src,
      imageChar: image2Char.src,
    },
    {
      name: "goal3",
      title: "Long term vision",
      text: [
        `We share your vision. That's why we are designing a different gaming experience. 
        We are 100% user focused with zero barriers to entry. 
        We want Sugar Kingdom to be the new paradigm in crypto 
        gaming, where users are the winners!`,
      ],
      imageHeader: image3Header.src,
      imageChar: image3Char.src,
    },
  ];
  const refSection = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [classNameSet, setClassName] = useState("");
  const [hoverOn, setHoverOn] = useState("");

  useEffect(() => {
    sectionsGoals.forEach((sect) => {
      // const imgHeader = new Image();
      // const imgChar = new Image();
      // imgHeader.src = sect.imageHeader;
      // imgChar.src = sect.imageChar;
    });
  }, []);

  useEffect(() => {
    setClassName(classes.animationContainer);
  }, [activeIndex]);

  const setTimer = () =>
    setTimeout(() => {
      setClassName(classes.animationContainer);
    }, 500);

  const updateIndex = (newIndex: number) => {
    setClassName("");
    if (newIndex >= 0 && newIndex < sectionsGoals.length) {
      setActiveIndex(newIndex);
      setTimer();
    } else if (newIndex >= sectionsGoals.length) {
      setActiveIndex(0);
      setTimer();
    }
  };

  return (
    <>
      <div className={classes.root} id="ourGoals">
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          className={classes.background}
        >
          <Grid
            direction="row"
            container
            alignItems="center"
            justifyContent="center"
            className={`${classes.bodyContainer} ${classNameSet}`}
          >
            <Grid
              direction="row"
              container
              alignItems="center"
              justifyContent="center"
              className={classes.contentContainer}
            >
              <Grid item xs={2} md={4} ref={refSection}></Grid>
              <Grid item xs={10} md={8} className={`${classes.rightContainer}`}>
                <Grid container alignItems="center">
                  <img
                    src={sectionsGoals[activeIndex].imageHeader}
                    alt="imageheader"
                    className={classes.imageHeader}
                  />
                </Grid>
                <Grid container alignItems="center" justifyContent="center">
                  <span className={classes.title}>
                    {sectionsGoals[activeIndex].title}
                  </span>
                </Grid>
                <Grid container alignItems="center" justifyContent="center">
                  {sectionsGoals[activeIndex].text.map((text, i) => (
                    <p className={classes.contentText} key={`goal${i}`}>
                      {text}
                    </p>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            direction="row"
            container
            alignItems="center"
            justifyContent="center"
            className={classes.paginatorContainer}
          >
            <Grid item xs={3} sm={1}>
              <img
                src={hoverOn === "left" ? prevEnabled : prevDisabled}
                alt="prev"
                className={classes.controlLeft}
                onClick={() => updateIndex(activeIndex - 1)}
                aria-hidden="true"
                onMouseEnter={() => setHoverOn("left")}
                onMouseLeave={() => setHoverOn("")}
              />
            </Grid>
            <Grid item xs={6} sm={10}>
              <div className={classes.indexContainer}>
                {sectionsGoals.map((section, i) => (
                  <div
                    key={`goalKey${i}`}
                    className={`${classes.indexSelector} ${
                      i === activeIndex
                        ? classes.indexEnabled
                        : classes.indexDisabled
                    }`}
                    onClick={() => updateIndex(i)}
                    aria-hidden="true"
                  ></div>
                ))}
              </div>
            </Grid>
            <Grid item xs={3} sm={1}>
              <img
                src={hoverOn === "right" ? nextEnabled : nextDisabled}
                alt="next"
                className={classes.controlRight}
                onClick={() => updateIndex(activeIndex + 1)}
                aria-hidden="true"
                onMouseEnter={() => setHoverOn("right")}
                onMouseLeave={() => setHoverOn("")}
              />
            </Grid>
          </Grid>
        </Grid>
        <div>
          <img
            src={sectionsGoals[activeIndex].imageChar}
            alt="imageheader"
            className={`${classes.imageChar}`}
          />
        </div>
      </div>
    </>
  );
};

export default LandingOurGoal;
